import {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import {getTokenResponse} from "../../utils/tokenResponse";
import {myApi} from "../../authConfig";
import {Cancel, Delete, Edit, Image, Save, Upload} from "@mui/icons-material";
import {useNewMedia} from "./NewMedia";
import mimelite from "mime/lite";
import {useShowImage} from "./ShowImage";

export type ProductImage = {
    image_id:string,
    position:number,
    name:string,
    alt:string,
    media: {
        original_file_name:string,
        media_id:string
    }
}

export const useProductImageDialog = () => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [productId,setProductId] = useState("");
    const [images,setImages] = useState<ProductImage[]>([]);
    const [editId,setEditId] = useState("");
    const [eNVP,setENVP] = useState<string>("0");
    const [eNVV,setENVV] = useState<string>("");
    const [eNVA,setENVA] = useState<string>("");

    const {dialog:dialogImg,interactions:intImg} = useShowImage();

    const {dialog:mediaDialog,interactions}=useNewMedia((files,ref)=>{
        if(ref==="add")
        {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                .then(async (headers)=>{

                    const sorted = images.sort((a,b)=>a.position-b.position)

                    let pos = 0;

                    if(sorted.length!==0)
                        pos = sorted[sorted.length-1].position

                    headers.append("Content-Type","application/json")

                    let options:RequestInit = {
                        method: "POST",
                        headers: headers
                    };

                    let nI = [...images];

                    for(const file of files)
                    {
                        pos +=1;

                        options = {
                            ...options,
                            body: JSON.stringify({
                                media_id:file.media_id,
                                position:pos
                            })
                        }

                        const data : {message:string,data:ProductImage} = await fetch(myApi.url+"/products/byId/"+productId+"/image",options)
                            .then(response => response.json())
                            .catch(error => console.log(error));

                        nI.push(data.data)
                    }

                    setImages(nI)


                })
        }
        else if(ref.startsWith("change-"))
        {
            const image_id = ref.replace("change-","");

            if(files.length===1)
            {
                const file = files[0];

                getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                    .then(async (headers)=>{

                        headers.append("Content-Type","application/json")

                        let options:RequestInit = {
                            method: "PUT",
                            headers: headers
                        };



                            options = {
                                ...options,
                                body: JSON.stringify({
                                    media_id:file.media_id
                                })
                            }

                            await fetch(myApi.url+"/products/byId/"+productId+"/image/"+image_id,options)
                                .then(response => response.json())
                                .catch(error => console.log(error));

                            setImages(images.filter((i)=>i.image_id!==image_id).concat(images.filter((i)=>i.image_id===image_id).map((i)=>{
                                return {
                                    ...i,
                                    media: file
                                }
                            })))

                    })
            }
        }
    },(file)=>{

        console.log("Filetype: "+mimelite.getType(file.name))

        const ok = (mimelite.getType(file.name) === "image/jpeg" || mimelite.getType(file.name) === "image/jpg" || mimelite.getType(file.name) === "image/png")

        if(!ok)
            alert("Dateityp nicht zulässig")

        return ok;
    })

    const onCloseDialog = () => {
        setDialogIsOpen(false)
    }

    const load = (pProductId:string) =>{
        setDialogIsOpen(true)
        setProductId(pProductId)

        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
            .then(async (headers)=>{

                const options = {
                    method: "GET",
                    headers: headers
                };

                const data: ProductImage[] = await fetch(myApi.url+"/products/byId/"+pProductId+"/images",options)
                    .then(response => response.json())
                    .catch(error => console.log(error));

                setImages(data)

            })

    }




    const dialog = <>
        <Dialog open={dialogIsOpen} onClose={onCloseDialog} fullWidth maxWidth={"lg"}>
            <DialogTitle>Produktbilder</DialogTitle>
            <DialogContent>
                <div className={"mt-2 space-y-3"}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Position</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Beschreibung</TableCell>
                                <TableCell>Dateiname</TableCell>
                                <TableCell>Aktionen</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                images.sort((a,b)=>a.position-b.position).map((image)=>(
                                    <TableRow>
                                        <TableCell>{editId===image.image_id?<TextField variant={"outlined"} value={eNVP} inputProps={{inputMode:"numeric"}} onChange={(e)=>setENVP(e.target.value)} />:image.position}</TableCell>
                                        <TableCell>{editId===image.image_id?<TextField variant={"outlined"} value={eNVV}  onChange={(e)=>setENVV(e.target.value)} />:image.name}</TableCell>
                                        <TableCell>{editId===image.image_id?<TextField variant={"outlined"} value={eNVA}  onChange={(e)=>setENVA(e.target.value)} />:image.alt}</TableCell>

                                        <TableCell>{image.media.original_file_name}</TableCell>
                                        <TableCell>
                                            <div className={"flex space-x-3"}>
                                                <Delete
                                                    className={"cursor-pointer"}
                                                    onClick={()=>{
                                                        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                            .then(async (headers)=>{

                                                                const options = {
                                                                    method: "DELETE",
                                                                    headers: headers
                                                                };

                                                                const d = await fetch(myApi.url+"/products/byId/"+productId+"/image/"+image.image_id,options)
                                                                    .then(response => response.json())
                                                                    .catch(error => console.log(error));

                                                                if(d.message==="success")
                                                                {
                                                                    setImages(images.filter((i)=>i.image_id!==image.image_id))
                                                                }

                                                            })
                                                    }}
                                                />
                                                {
                                                    editId === image.image_id ? <>
                                                        <Save
                                                            className={"cursor-pointer"}
                                                            onClick={()=>{
                                                                getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                                    .then(async (headers)=>{
                                                                        headers.append('Content-Type', 'application/json')

                                                                        const options = {
                                                                            method: "POST",
                                                                            headers: headers,
                                                                            body: JSON.stringify({
                                                                                name:eNVV,
                                                                                position:parseInt(eNVP),
                                                                                alt:eNVA
                                                                            })
                                                                        };

                                                                        const data = await fetch(myApi.url+"/products/byId/"+productId+"/image/"+image.image_id,options)
                                                                            .then(response => response.json())
                                                                            .catch(error => console.log(error));

                                                                        if(data.message==="success")
                                                                        {
                                                                            setEditId("");
                                                                            setENVP("0")
                                                                            setENVV("")
                                                                            setENVA("");

                                                                            setImages(images.filter((i)=>i.image_id!==image.image_id).concat(images.filter((i)=>i.image_id===image.image_id).map((i)=>{
                                                                                return {
                                                                                    ...i,
                                                                                    name:eNVV,
                                                                                    alt:eNVA,
                                                                                    position:parseInt(eNVP)
                                                                                }
                                                                            })))

                                                                        }

                                                                    })
                                                            }}

                                                        />
                                                        <Cancel
                                                            className={"cursor-pointer"}
                                                            onClick={()=>{
                                                                setEditId("")
                                                                setENVP("0")
                                                                setENVV("")
                                                                setENVA("")
                                                            }}
                                                        />
                                                    </> : <>
                                                    <Edit
                                                        className={"cursor-pointer"}
                                                        onClick={()=>{
                                                            setEditId(image.image_id)
                                                            setENVV(image.name);
                                                            setENVP(""+image.position)
                                                            setENVA(image.alt)
                                                        }}/>
                                                        <Upload
                                                            className={"cursor-pointer"}
                                                            onClick={()=>{
                                                                interactions.open(false,"change-"+image.image_id)
                                                            }}
                                                        />
                                                    </>

                                                }
                                                <Image
                                                    className={"cursor-pointer"}
                                                    onClick={()=>{
                                                        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                            .then(async (headers)=>{

                                                                const options = {
                                                                    method: "GET",
                                                                    headers: headers
                                                                };

                                                                const data = await fetch(myApi.url+"/media/"+image.media.media_id+"/getLink/60",options)
                                                                    .then(response => response.json())
                                                                    .catch(error => console.log(error));

                                                                if(data.message==="success")
                                                                {
                                                                    intImg.open(data.link)

                                                                }

                                                            })
                                                    }}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} startIcon={<Upload/>} onClick={()=>{
                    interactions.open(true,
                        "add"
                    )}}>
                    Hinzufügen
                </Button>
                <Button variant={"contained"} onClick={onCloseDialog}>
                    Schließen
                </Button>
            </DialogActions>
            {mediaDialog}
            {dialogImg}
        </Dialog>
    </>;

    return {
        dialog:dialog,
        interactions: {
            load:load
        }
    }

}