import React, {ReactElement, useState} from "react";
import {
    Alert,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Divider,
    FormControl,
    FormGroup,
    Grid,
    InputLabel, MenuItem,
    Select, Snackbar,
    TextField
} from "@mui/material";
import {getTokenResponse} from "../../utils/tokenResponse";
import {myApi} from "../../authConfig";
import {ProductGroup} from "../data_grids/ProductDataGrid";
import {ProductCategory} from "../product_categories";
import {Category, CategoryPrisma} from "../../types/Category";
import {SelectCategory} from "./SelectCategory";
import {useAttributeValuesEditor} from "./AttributeValuesEditor";

type ProductGroupEditorReturns = {
    dialog:ReactElement,
    interactions:ProductGroupEditorInteractions
}

type ProductGroupEditorInteractions = {
    load:(product_group_id:string)=>void,
    loadAndOpen:(product_group_id:string)=>void,
    open:(lCat?:Category|null)=>void,
    reset:()=>void

}

export interface ProductGroupWithCategories extends ProductGroup {
    categories: {
        category: CategoryPrisma
    }[]
}

export const useProductGroupEditor = (reload?:()=>void):ProductGroupEditorReturns => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false)

    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);

    const [selectedCategoriesForDelete, setSelectedCategoriesForDelete] = useState<string[]>([]);

    const [productGroup,setProductGroup] = useState<ProductGroupWithCategories>({
        product_group_id: "",
        name:"",
        product_number:"",
        product_category: {
            name:"",
            id:""
        },
        product_category_id: "",
        categories:[]
    });

    const [snackbarIsOpen, setSnackbarIsOpen] = useState<boolean>(false);

    const [snackbarMessage, setSnackbarMessage] = useState<string>("");

    const {dialog:dialogA,interactions} = useAttributeValuesEditor();

    const reset = () => {
        setProductGroup({
            product_group_id: "",
            name:"",
            product_number:"",
            product_category: {
                name:"",
                id:""
            },
            product_category_id: "",
            categories:[]
        })
        setSelectedCategoriesForDelete([]);
    }



    const load = (product_group_id:string) =>{

        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Write"])
            .then(async (headers)=>{
                const options = {
                    method: "GET",
                    headers:headers
                }


                const data = await fetch(myApi.url+"/product_groups/byId/"+product_group_id, options)
                    .then(response => response.json())
                    .catch(error => console.log(error));

                setProductGroup(data)


            })
    }



    const loadAndOpen = (product_group_id:string) => {
        load(product_group_id);
        open();

    }

    const onCloseDialog = () => {
        setDialogIsOpen(false);
        reset()
    }

    const open = (lCat?:Category|null) => {
        setDialogIsOpen(true)

        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Write"])
            .then(async (headers)=>{
                const options = {
                    method: "GET",
                    headers:headers
                }

                const data2 = await fetch(myApi.url+"/product_categories", options)
                    .then(response => response.json())
                    .catch(error => console.log(error));

                setProductCategories(data2)



            })

        if(productGroup.product_group_id===""&&lCat&&lCat.category_id)
        {
            setProductGroup({
                ...productGroup,
                categories: [{
                    category: {
                        category_id:lCat.category_id,
                        name:lCat.name,
                        parent_category_id:lCat.parent_category_id
                    }
                }]
            })
        }
    }

    const onCategoryAdd = (lCat_id:string|null,lCat?:Category|null) => {

        let skip = false;

        if(!lCat_id||!lCat)
        {
            setSnackbarMessage("Es darf nicht die Basiskategorie ausgewählt werden.")
            setSnackbarIsOpen(true)
            return;
        }

        productGroup.categories.forEach((fCat)=>{
            if(fCat.category.category_id === lCat_id)
            {
                setSnackbarMessage(`Das Produkt ist bereits in der Kategorie ${fCat.category.name}.`)
                setSnackbarIsOpen(true)
                skip=true;
                return;
            }
        })

        if(skip)
            return;

        setProductGroup(
            {
                ...productGroup,
                categories: productGroup.categories.concat({
                    category: {
                        category_id:lCat_id,
                        name: lCat.name,
                        parent_category_id: lCat.parent_category_id
                    }
                })
            }
        )

    }

    const deleteCategories = () => {

        setProductGroup({...productGroup, categories: productGroup.categories.filter((lCat)=> !selectedCategoriesForDelete.includes(lCat.category.category_id))})

        setSelectedCategoriesForDelete([]);
    }

    const save = () => {

        if(productGroup.product_group_id!== "")
        {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Write"])
                .then(async (headers)=>{
                    headers.append('Content-Type', 'application/json')

                    const options = {
                        method: "PUT",
                        headers:headers,
                        body: JSON.stringify({
                            name: productGroup.name,
                            product_number: productGroup.product_number,
                            product_category: productGroup.product_category_id,
                            categories: productGroup.categories.map((lC) => {
                                return lC.category.category_id
                            })
                        })
                    }



                    await fetch(myApi.url+"/product_groups/"+productGroup.product_group_id, options)
                        .then(response => response.json())
                        .catch(error => console.log(error));

                    onCloseDialog()
                    if(reload)
                        reload()


                })
        }
        else
        {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Write"])
                .then(async (headers)=>{
                    headers.append('Content-Type', 'application/json')

                    const options = {
                        method: "POST",
                        headers:headers,
                        body: JSON.stringify({
                            name: productGroup.name,
                            product_number: productGroup.product_number,
                            product_category: productGroup.product_category_id,
                            categories: productGroup.categories.map((lC) => {
                                return lC.category.category_id
                            })
                        })
                    }



                    await fetch(myApi.url+"/product_groups", options)
                        .then(response => response.json())
                        .catch(error => console.log(error));

                    onCloseDialog()
                    if(reload)
                        reload()


                })
        }

    }

    const dialog = <>
        <Dialog open={dialogIsOpen} onClose={onCloseDialog} fullWidth maxWidth={"lg"}>
            <DialogTitle>Produktgruppeneditor</DialogTitle>

            <form onSubmit={(e)=> {
                e.preventDefault()
                save()
            }}>
                <DialogContent>
                    <FormControl className={"w-full"}>
                        <FormGroup className={"m-2 space-y-2"}>
                            <Grid container spacing={2} className={"w-full"}>
                                <Grid item xs={12}>
                                    <Divider>Stammdaten</Divider>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required className={"w-full"} label={"Produktgruppenname"} value={productGroup.name} onChange={(e)=>setProductGroup({...productGroup,name:e.target.value})}></TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField required label={"Produktgruppennummer"} value={productGroup.product_number} onChange={(e)=>setProductGroup({...productGroup,product_number:e.target.value})}></TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Produktkategorie</InputLabel>
                                        <Select

                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={productGroup.product_category_id}
                                            label="Produktkategorie"
                                            onChange={(e)=>
                                                setProductGroup({...productGroup,product_category_id:e.target.value})
                                            }
                                        >

                                            {
                                                productCategories.map((productCategory)=>
                                                    (
                                                        <MenuItem value={productCategory.id}>{productCategory.name}</MenuItem>
                                                    )
                                                )
                                            }

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider>Kategorien</Divider>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink id="cat">Kategorien</InputLabel>
                                        <Select

                                            native
                                            multiple
                                            labelId="cat"
                                            label="Kategorien"
                                            value={selectedCategoriesForDelete}
                                            //@ts-ignore
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                const { options } = event.target;
                                                const value: string[] = [];
                                                for (let i = 0, l = options.length; i < l; i += 1) {
                                                    if (options[i].selected) {
                                                        value.push(options[i].value);
                                                    }
                                                }
                                                setSelectedCategoriesForDelete(value);
                                            }}

                                        >

                                            {
                                                productGroup.categories.map((lCat)=>
                                                    (
                                                        <option value={lCat.category.category_id} key={lCat.category.category_id}>{lCat.category.name}</option>
                                                    )
                                                )
                                            }

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={"flex flex-col space-y-2"}>
                                        <SelectCategory title={"Kategorie hinzufügen"} onSelection={onCategoryAdd}>
                                            Kategorie hinzufügen
                                        </SelectCategory>
                                        <Button color={"error"} disabled={selectedCategoriesForDelete.length===0} variant={"contained"} onClick={deleteCategories}>
                                            Ausgewählte löschen
                                        </Button>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Button variant={"contained"} onClick={()=>{interactions.load("product_group",productGroup.product_group_id)}}>
                                        Attribute bearbeiten
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormGroup>

                    </FormControl>
                    <DialogActions>
                        <Button onClick={onCloseDialog} color="error">
                            Abbrechen
                        </Button>
                        <Button type={"submit"} color="primary" variant={"contained"} >
                            Speichern
                        </Button>
                    </DialogActions>
                </DialogContent>
            </form>
            {dialogA}
        </Dialog>
        <Snackbar open={snackbarIsOpen} autoHideDuration={3000} onClose={()=>setSnackbarIsOpen(false)}>
            <Alert severity="error" onClose={()=>setSnackbarIsOpen(false)}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </>;

    return {
        dialog:dialog,
        interactions: {
            load:load,
            loadAndOpen:loadAndOpen,
            open:open,
            reset:reset
        }
    };

}