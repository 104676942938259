import {useState} from "react";
import {
    Autocomplete, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select, TextField, Tooltip
} from "@mui/material";
import {ProductCategory} from "../product_categories";
import {getTokenResponse} from "../../utils/tokenResponse";
import {myApi} from "../../authConfig";
import {Attribute} from "../../pages/Attributes";
import {GridDeleteIcon} from "@mui/x-data-grid";
import {SelectAttribute} from "./SelectAttribute";
import {Info} from "@mui/icons-material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import {SelectInheritance} from "./SelectInheritance";
import {Product} from "./ProductEditor";
import {ConnectProductGroup} from "../../types/connect_product_group";
import {ViewProductAttributes} from "./ViewProductAttributes";

interface Pc extends ProductCategory {
    preselectedAttributes: PreSelAttr[],
    attribute_values: AValue[]
}

type PreSelAttr ={

        activeForProductGroups:boolean,
            activeForProducts:boolean,
        attribute: Attribute

}

type AValue = {
    attribute:Attribute,
    type: "AC_VALUE"|"STR",
    value?:string,
    acValue?:ACValue,
    attribute_value_id:string
}

export type ACValue = {
    autocomplete_id:string,
    name:string
}

export type PGInheritance = {
    attribute_id:string,
    id:string,
    inheritance_option:"OVERWRITE"|"ADDITIONAL"|"INHERIT"
}

export const useAttributeValuesEditor = () => {

    const [type,setType] = useState<"product_category"|"product_group"|"product">("product");

    const [id,setId] = useState<string>("");

    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const [pC,setPC] =useState<Pc>()

    const [product,setProduct] = useState<Product>()

    const [attributes, setAttributes] = useState<Attribute[]>([]);
    const [values,setValues] = useState<AValue[]>([]);

    const [pcAttr,setPcAttr] = useState<Attribute[]>([]);

    const [connectPg,setConnectPg] = useState<ConnectProductGroup>();
    const onCloseDialog = () => {
        setDialogIsOpen(false)
        setValues([]);
        setAttributes([])
        setConnectPg(undefined);
        setPcAttr([])
        setProduct(undefined)
        setPC(undefined)
        setInheritances([])
    }

    const [inheritances,setInheritances]=useState<PGInheritance[]>([]);

    const load = (lType:"product_category"|"product_group"|"product",lId:string) =>
    {
        setType(lType);
        setId(lId);

        if(lType==="product_category")
        {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                .then(async (headers)=>{
                    const options = {
                        method: "GET",
                        headers:headers
                    }

                    const data2 = await fetch(myApi.url+"/product_categories/"+lId, options)
                        .then(response => response.json())
                        .catch(error => console.log(error));

                    setPC(data2)
                    let pre:Attribute[] = data2.preselectedAttributes.map((p:PreSelAttr)=>p.attribute)



                    data2.attribute_values.forEach((p:AValue)=>{
                        if(pre.filter((p2)=>p2.attribute_id===p.attribute.attribute_id).length===0)
                        {
                            pre = pre.concat(p.attribute)
                        }

                    })

                    setAttributes(pre);
                    setValues(data2.attribute_values)
                })
        }
        else if(lType==="product_group")
        {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                .then(async (headers)=>{
                    const options = {
                        method: "GET",
                        headers:headers
                    }

                    const data2 = await fetch(myApi.url+"/product_groups/withAttr/"+lId, options)
                        .then(response => response.json())
                        .catch(error => console.log(error));

                    let pre:Attribute[] = data2.product_category.preselectedAttributes.filter((f:PreSelAttr)=>f.activeForProductGroups).map((p:PreSelAttr)=>p.attribute)



                    data2.attribute_values.forEach((p:AValue)=>{
                        if(pre.filter((p2)=>p2.attribute_id===p.attribute.attribute_id).length===0)
                        {
                            pre = pre.concat(p.attribute)
                        }

                    })

                    const data3 = await fetch(myApi.url+"/attributes/allProductGroups", options)
                        .then(response => response.json())
                        .catch(error => console.log(error));

                    data3.forEach((a:Attribute)=>{
                        if(pre.filter((p2)=>p2.attribute_id===a.attribute_id).length===0)
                        {
                            pre = pre.concat(a)
                        }
                    })

                    setPC(data2.product_category)

                    let at :Attribute[] = [];

                    data2.product_category.attribute_values.forEach((v:AValue)=>{
                        if(at.filter((val)=>val.attribute_id===v.attribute.attribute_id).length===0)
                            at = at.concat(v.attribute)
                    })


                    setInheritances(data2.attribute_inheritances)

                    setPcAttr(at);

                    data2.categories.forEach((category:{category:{preselectedAttributes:PreSelAttr[]}})=>{
                        category.category.preselectedAttributes.forEach((p)=>{
                            if(pre.filter((p2)=>p2.attribute_id===p.attribute.attribute_id).length===0)
                            {
                                if(p.activeForProductGroups)
                                {
                                    pre = pre.concat(p.attribute)
                                }
                            }
                        })
                    })

                    setAttributes(pre);
                    setValues(data2.attribute_values)
                })
        }
        else if(lType==="product")
        {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                .then(async (headers)=>{
                    const options = {
                        method: "GET",
                        headers:headers
                    }

                    const data2 = await fetch(myApi.url+"/products/withAttr/"+lId, options)
                        .then(response => response.json())
                        .catch(error => console.log(error));

                    let pre:Attribute[] = [];
                    let at :Attribute[] = [];

                    if(data2.product_category_inheritance_option==="INHERIT")
                    {
                         pre = data2.product_group.product_category.preselectedAttributes.filter((f:PreSelAttr)=>f.activeForProducts).map((p:PreSelAttr)=>p.attribute)
                        setPC(data2.product_group.product_category)
                        data2.product_group.product_category.attribute_values.forEach((v:AValue)=>{
                            if(at.filter((val)=>val.attribute_id===v.attribute.attribute_id).length===0)
                                at = at.concat(v.attribute)
                        })
                    }
                    else {
                         pre = data2.product_category.preselectedAttributes.filter((f:PreSelAttr)=>f.activeForProducts).map((p:PreSelAttr)=>p.attribute)
                        setPC(data2.product_category)
                        data2.product_category.attribute_values.forEach((v:AValue)=>{
                            if(at.filter((val)=>val.attribute_id===v.attribute.attribute_id).length===0)
                                at = at.concat(v.attribute)
                        })
                    }



                    data2.attribute_values.forEach((p:AValue)=>{
                        if(pre.filter((p2)=>p2.attribute_id===p.attribute.attribute_id).length===0)
                        {
                            pre = pre.concat(p.attribute)
                        }

                    })

                    setProduct(data2)
                    setInheritances(data2.attribute_inheritances)



                    const data3 = await fetch(myApi.url+"/attributes/allProducts", options)
                        .then(response => response.json())
                        .catch(error => console.log(error));

                    data3.forEach((a:Attribute)=>{
                        if(pre.filter((p2)=>p2.attribute_id===a.attribute_id).length===0)
                        {
                            pre = pre.concat(a)
                        }
                    })







                    setPcAttr(at);

                    if(data2.product_group)
                    {
                        setConnectPg(await fetch(myApi.url+"/connect/product_group/"+data2.product_group_id, options)
                            .then(response => response.json())
                            .catch(error => console.log(error)))

                        if(data2.category_inheritance_option==="ADDITIONAL"||data2.category_inheritance_option==="OVERWRITE")
                        {
                            data2.categories.forEach((category:{category:{preselectedAttributes:PreSelAttr[]}})=>{
                                category.category.preselectedAttributes.forEach((p)=>{
                                    if(pre.filter((p2)=>p2.attribute_id===p.attribute.attribute_id).length===0)
                                    {
                                        if(p.activeForProducts)
                                        {
                                            pre = pre.concat(p.attribute)
                                        }
                                    }
                                })
                            })
                        }

                        if(data2.category_inheritance_option==="ADDITIONAL"||data2.category_inheritance_option==="INHERIT")
                        {
                            data2.product_group.categories.forEach((category:{category:{preselectedAttributes:PreSelAttr[]}})=>{
                                category.category.preselectedAttributes.forEach((p)=>{
                                    if(pre.filter((p2)=>p2.attribute_id===p.attribute.attribute_id).length===0)
                                    {
                                        if(p.activeForProducts)
                                        {
                                            pre = pre.concat(p.attribute)
                                        }
                                    }
                                })
                            })
                        }
                    }
                    else {
                        data2.categories.forEach((category:{category:{preselectedAttributes:PreSelAttr[]}})=>{
                            category.category.preselectedAttributes.forEach((p)=>{
                                if(pre.filter((p2)=>p2.attribute_id===p.attribute.attribute_id).length===0)
                                {
                                    if(p.activeForProducts)
                                    {
                                        pre = pre.concat(p.attribute)
                                    }
                                }
                            })
                        })
                    }



                    setAttributes(pre);
                    setValues(data2.attribute_values)
                })
        }

        setDialogIsOpen(true)
    }


    const dialog = <>
        <Dialog open={dialogIsOpen} fullWidth maxWidth={"lg"} onClose={onCloseDialog}>
            <DialogTitle>Attribut-Werte</DialogTitle>
            <DialogContent>
                <div className={"w-full space-y-4"}>
                    <Divider>Attributwerte</Divider>
                        <SelectAttribute onSelection={(option)=>{
                            if(attributes.filter((val)=>val.attribute_id===option.attribute_id).length===0)
                            {
                                setAttributes(attributes.concat(option))
                            }
                            else {
                                alert("Das Attribut wird bereits angezeigt.")
                            }
                        }}>
                            <Button variant={"contained"}>
                                Attribut hinzufügen
                            </Button>
                        </SelectAttribute>
                    <Grid container spacing={2}>
                        {
                            attributes.map((a)=>(
                                <Grid item xs={6}>
                                    <div className={"flex items-center space-x-2 w-full"}>
                                        {
                                            !a.isFreeText ? <>
                                                {
                                                    !a.multipleValues ? <>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={a.attribute_id+"-label"}>{a.name}</InputLabel>
                                                            <Select
                                                                labelId={a.attribute_id+"-label"}
                                                                id={a.attribute_id}
                                                                label={a.name}
                                                                onChange={(e)=>{
                                                                    const filter = values.filter((val)=>val.attribute.attribute_id===a.attribute_id);

                                                                    if(filter.length===0)
                                                                    {
                                                                        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                                            .then(async (headers) => {

                                                                                headers.append('Content-Type', 'application/json')

                                                                                const options = {
                                                                                    method: 'POST',
                                                                                    headers: headers,
                                                                                    body: JSON.stringify({
                                                                                        type:"AC_VALUE",
                                                                                        acValue_id:e.target.value
                                                                                    })
                                                                                }
                                                                                const lR = await fetch(myApi.url + "/attribute_values/"+type +"/"+ id + "/"+a.attribute_id+"/", options)
                                                                                    .then(response => response.json())
                                                                                    .catch(error => console.log(error));

                                                                                if(lR.message==="success")
                                                                                {
                                                                                    setValues(values.concat({...lR.data,attribute:a,acValue:a.autocomplete_values.find((val)=>val.autocomplete_id===e.target.value)}))
                                                                                }
                                                                            })
                                                                    }
                                                                    else {
                                                                        setValues(values.filter((val)=>val.attribute.attribute_id!==a.attribute_id).concat({...filter[0],acValue:a.autocomplete_values.find((val)=>val.autocomplete_id===e.target.value)}))

                                                                        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                                            .then(async (headers) => {

                                                                                headers.append('Content-Type', 'application/json')

                                                                                const options = {
                                                                                    method: 'POST',
                                                                                    headers: headers,
                                                                                    body: JSON.stringify({
                                                                                        type:"AC_VALUE",
                                                                                        acValue_id:e.target.value
                                                                                    })
                                                                                }
                                                                               await fetch(myApi.url + "/attribute_values/"+type +"/"+ id + "/"+a.attribute_id+"/"+filter[0].attribute_value_id, options)
                                                                                    .then(response => response.json())
                                                                                    .catch(error => console.log(error));
                                                                            })
                                                                    }
                                                                }}
                                                                value={values.filter((val)=>val.attribute.attribute_id===a.attribute_id)[0]?.acValue ? values.filter((val)=>val.attribute.attribute_id===a.attribute_id)[0]?.acValue?.autocomplete_id:''}
                                                            >
                                                                {
                                                                    a.autocomplete_values.map((v)=>(
                                                                        <MenuItem value={v.autocomplete_id}>{v.name}</MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                        <GridDeleteIcon

                                                            className={"cursor-pointer"}

                                                            onClick={()=>{
                                                                const filter = values.filter((val)=>val.attribute.attribute_id===a.attribute_id);

                                                                if(filter.length===1)
                                                                {
                                                                getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                                    .then(async (headers) => {

                                                                        headers.append('Content-Type', 'application/json')

                                                                        const options = {
                                                                            method: 'DELETE',
                                                                            headers: headers
                                                                        }
                                                                        const lR = await fetch(myApi.url + "/attribute_values/"+type+"/"+filter[0].attribute_value_id, options)
                                                                            .then(response => response.json())
                                                                            .catch(error => console.log(error));

                                                                        if(lR.message==="success")
                                                                        {
                                                                            setValues(values.filter((val)=>val.attribute_value_id!==filter[0].attribute_value_id))
                                                                        }
                                                                    })
                                                                }
                                                            }}

                                                        />
                                                    </>:<div className={"w-full"}>
                                                        <Autocomplete
                                                            multiple
                                                            id={a.attribute_id}
                                                            filterSelectedOptions
                                                            options={a.autocomplete_values}
                                                            getOptionLabel={(option)=>option?option.name:""}
                                                            value={values.filter((val)=>val.attribute.attribute_id===a.attribute_id&&val.acValue).map((val)=> a.autocomplete_values.find((v)=>v.autocomplete_id===val.acValue?.autocomplete_id))}
                                                            renderInput={(params)=>(
                                                                <TextField
                                                                    {...params}
                                                                    label={a.name}
                                                                />
                                                            )}
                                                            onChange={async(c,b)=>{
                                                                if(b) {
                                                                    const oldValuesFF = values.filter((val)=>val.attribute.attribute_id===a.attribute_id);

                                                                    const delValues = oldValuesFF.filter((val)=>!b.map((v)=>v?.autocomplete_id).includes(val.acValue?.autocomplete_id))

                                                                    const newValues = b.filter((val)=>!values.map((v)=>v.acValue?.autocomplete_id).includes(val?.autocomplete_id))


                                                                    if(delValues.length!==0||newValues.length!==0)
                                                                    {

                                                                        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                                            .then(async (headers) => {

                                                                                let nV = [...values];

                                                                                headers.append('Content-Type', 'application/json')

                                                                                let options:RequestInit = {
                                                                                    headers: headers
                                                                                }

                                                                                for(const delValue of delValues) {
                                                                                    options = {...options,method:"DELETE"}
                                                                                    await fetch(myApi.url + "/attribute_values/"+type+"/"+delValue.attribute_value_id, options)
                                                                                        .then(response => response.json())
                                                                                        .catch(error => console.log(error));

                                                                                    nV = nV.filter((val)=>val.attribute_value_id!==delValue.attribute_value_id)
                                                                                }

                                                                                for(const newValue of newValues) {
                                                                                    if(newValue)
                                                                                    {
                                                                                        options = {
                                                                                            ...options,
                                                                                            method:"POST",
                                                                                            body: JSON.stringify({
                                                                                                type:"AC_VALUE",
                                                                                                acValue_id:newValue.autocomplete_id
                                                                                            })
                                                                                        }
                                                                                        const lR = await fetch(myApi.url + "/attribute_values/"+type+"/"+id+"/"+a.attribute_id+"/", options)
                                                                                            .then(response => response.json())
                                                                                            .catch(error => console.log(error));

                                                                                        nV = nV.concat({
                                                                                            ...lR.data,
                                                                                            attribute:a,
                                                                                            acValue:a.autocomplete_values.find((val)=>val.autocomplete_id===newValue.autocomplete_id)
                                                                                        })
                                                                                    }
                                                                                }
                                                                                setValues(nV)

                                                                            })
                                                                    }

                                                                }
                                                            }}

                                                        />
                                                    </div>
                                                }
                                            </>:<>
                                            {
                                                !a.multipleValues ? <div className={"w-full"}>
                                                        <Autocomplete
                                                            freeSolo
                                                            renderInput={(params)=><TextField {...params} label={a.name} />}
                                                            options={a.autocomplete_values.map((val)=>val.name)}
                                                            value={values.filter((val)=>val.attribute.attribute_id===a.attribute_id)[0]? (values.filter((val)=>val.attribute.attribute_id===a.attribute_id)[0].type==="STR"?values.filter((val)=>val.attribute.attribute_id===a.attribute_id)[0].value : values.filter((val)=>val.attribute.attribute_id===a.attribute_id)[0]?.acValue?.name) :""}
                                                            onChange={(e,value)=>{


                                                                if(!value && values.filter((val)=>val.attribute.attribute_id===a.attribute_id).length===0)
                                                                    return;
                                                                if(value && values.filter((val)=>val.attribute.attribute_id===a.attribute_id).length===1&&((values.filter((val)=>val.attribute.attribute_id===a.attribute_id)[0].type==="AC_VALUE"&&values.filter((val)=>val.attribute.attribute_id===a.attribute_id)[0]?.acValue?.name===value)||(values.filter((val)=>val.attribute.attribute_id===a.attribute_id)[0].type==="STR"&&values.filter((val)=>val.attribute.attribute_id===a.attribute_id)[0].value===value)))
                                                                    return;
                                                                getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                                    .then(async (headers) => {
                                                                        headers.append('Content-Type', 'application/json')

                                                                        let options:RequestInit = {
                                                                            headers: headers
                                                                        }


                                                                        const v = values.find((val)=>val.attribute.attribute_id===a.attribute_id);

                                                                if(value)
                                                                {
                                                                    const ac = a.autocomplete_values.find((val)=>val.name===value);

                                                                    let lV = [...values];
                                                                    if(v)
                                                                        lV = lV.filter((val)=>val.attribute_value_id!==v.attribute_value_id)
                                                                    if(ac)
                                                                    {
                                                                        options = {
                                                                            ...options,
                                                                            method:"POST",
                                                                            body: JSON.stringify({
                                                                                type:"AC_VALUE",
                                                                                acValue_id:ac.autocomplete_id,

                                                                            })
                                                                        }


                                                                    }
                                                                    else {
                                                                        options = {
                                                                            ...options,
                                                                            method:"POST",
                                                                            body: JSON.stringify({
                                                                                type:"STR",
                                                                                value:value

                                                                            })
                                                                        }
                                                                    }
                                                                    const lR = await fetch(myApi.url + "/attribute_values/"+type+"/"+id+"/"+a.attribute_id+"/"+(v?v.attribute_value_id:""), options)
                                                                        .then(response => response.json())
                                                                        .catch(error => console.log(error));

                                                                    if(ac)
                                                                        setValues(lV.concat({...lR.data,attribute:a,acValue:ac}))
                                                                    else
                                                                        setValues(lV.concat({...lR.data,attribute:a}))
                                                                }
                                                                else {
                                                                    options = {
                                                                        ...options,
                                                                        method:"DELETE"
                                                                    }

                                                                    await fetch(myApi.url + "/attribute_values/"+type+"/"+(v?v.attribute_value_id:""), options)
                                                                        .then(response => response.json())
                                                                        .catch(error => console.log(error));

                                                                    setValues(values.filter((val)=>val.attribute_value_id!==v?.attribute_value_id))

                                                                }
                                                                    })

                                                            }}
                                                        />
                                                    </div>:
                                                    <div className={"w-full"}>
                                                        <Autocomplete
                                                            multiple
                                                            renderInput={(params)=>(<TextField {...params} label={a.name}/>)}
                                                            value={values.filter((val)=>val.attribute.attribute_id===a.attribute_id).map((val)=>val.type==="STR"?val.value:val.acValue?.name)}
                                                            options={a.autocomplete_values.map((val)=>val.name)}
                                                            getOptionLabel={(option)=>(typeof option === 'string'?option:"")}
                                                            freeSolo
                                                            onChange={(c,b)=>{
                                                                const oldValues = values.filter((val)=>val.attribute.attribute_id===a.attribute_id);

                                                                const delValues = oldValues.filter((val)=>!b.includes(val.type==="STR"?val.value:val.acValue?.name))

                                                                const newValues = b.filter((val)=>!oldValues.map((v)=>v.type==="STR"?v.value:v.acValue?.name).includes(val))


                                                                if(delValues.length!==0||newValues.length!==0)
                                                                {

                                                                    getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                                        .then(async (headers) => {

                                                                            let nV = [...values];

                                                                            headers.append('Content-Type', 'application/json')

                                                                            let options:RequestInit = {
                                                                                headers: headers
                                                                            }

                                                                            for(const delValue of delValues) {
                                                                                options = {...options,method:"DELETE"}
                                                                                await fetch(myApi.url + "/attribute_values/"+type+"/"+delValue.attribute_value_id, options)
                                                                                    .then(response => response.json())
                                                                                    .catch(error => console.log(error));

                                                                                nV = nV.filter((val)=>val.attribute_value_id!==delValue.attribute_value_id)
                                                                            }

                                                                            for(const newValue of newValues) {
                                                                                if(newValue)
                                                                                {

                                                                                    const ac = a.autocomplete_values.find((val)=>val.name===newValue)

                                                                                    if(ac)
                                                                                    {
                                                                                        options = {
                                                                                            ...options,
                                                                                            method:"POST",
                                                                                            body: JSON.stringify({
                                                                                                type:"AC_VALUE",
                                                                                                acValue_id:ac.autocomplete_id
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        options = {
                                                                                            ...options,
                                                                                            method:"POST",
                                                                                            body: JSON.stringify({
                                                                                                type:"STR",
                                                                                                value:newValue
                                                                                            })
                                                                                        }
                                                                                    }

                                                                                    const lR = await fetch(myApi.url + "/attribute_values/"+type+"/"+id+"/"+a.attribute_id+"/", options)
                                                                                        .then(response => response.json())
                                                                                        .catch(error => console.log(error));

                                                                                    nV = nV.concat({
                                                                                        ...lR.data,
                                                                                        attribute:a,
                                                                                        acValue:a.autocomplete_values.find((val)=>val.autocomplete_id===ac?.autocomplete_id)
                                                                                    })
                                                                                }
                                                                            }


                                                                            setValues(nV)

                                                                        })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                            }
                                            </>
                                        }
                                    </div>
                                </Grid>
                            ))
                        }
                    </Grid>
                    {
                        (type==="product_group"||(type==="product"&&product?.product_category_inheritance_option==="OVERWRITE"))&&pC&&(
                            <>
                                <Divider>Geerbt aus der Produktkategorie</Divider>
                                <Grid container spacing={2}>
                                    {
                                        pcAttr.map((a)=>(
                                            <Grid item xs={6}>
                                                {
                                                    a.multipleValues ? <div className={"flex w-full items-center space-x-4"}>
                                                        <Autocomplete
                                                            className={"w-full"}
                                                            disabled
                                                            readOnly
                                                            multiple
                                                            renderInput={(params)=>(<TextField {...params} label={a.name} />)}
                                                            defaultValue={pC.attribute_values.filter((val)=>val.attribute.attribute_id===a.attribute_id).map((val)=>(val.type==="STR"?val.value:val.acValue?.name))}
                                                            options={pC.attribute_values.filter((val)=>val.attribute.attribute_id===a.attribute_id).map((val)=>(val.type==="STR"?val.value:val.acValue?.name))}/>
                                                        <div>
                                                            <Tooltip title={inheritances.find((i)=>i.attribute_id===a.attribute_id)||inheritances.find((i)=>i.attribute_id===a.attribute_id)?.inheritance_option==="ADDITIONAL"?(inheritances.find((i)=>i.attribute_id===a.attribute_id)?.inheritance_option==="OVERWRITE"?"Es werden nur objekt-spezifische Werte genutzt":"Es werden nur Werte des nächst höheren Elements genutzt genutzt"):"Weitere Werte werden hinzugefügt."}>
                                                                <Info></Info>
                                                            </Tooltip>
                                                            {
                                                                connectPg ? (
                                                                <Tooltip title={"Vererbungshinweis: Produktkategorie und Produktgruppe werden zusammen betrachtet. Die Vererbung kann nur bei der Produktgruppe geändert werden."} >
                                                                    <ImportExportIcon />
                                                                </Tooltip>
                                                                ) : (
                                                                    <SelectInheritance onSelection={(i)=>{

                                                                        setInheritances(inheritances.filter((val)=>val.attribute_id!==a.attribute_id).concat({
                                                                            attribute_id:a.attribute_id,
                                                                            inheritance_option:i,
                                                                            id:id
                                                                        }))
                                                                        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                                            .then(async (headers) => {
                                                                                let options:RequestInit = {
                                                                                    headers: headers,
                                                                                    method:"GET"
                                                                                }

                                                                                await fetch(myApi.url + "/attribute_values/inheritance/"+type+"/"+a.attribute_id+"/"+id+"/"+i, options)
                                                                                    .then(response => response.json())
                                                                                    .catch(error => console.log(error));
                                                                            })

                                                                    }}>
                                                                        <Tooltip title={"Vererbung ändern"} >
                                                                            <ImportExportIcon className={"cursor-pointer"}/>
                                                                        </Tooltip>
                                                                    </SelectInheritance>
                                                                )
                                                            }

                                                        </div>
                                                    </div>:<div className={"flex items-center space-x-4"}>
                                                        <TextField className={"w-full"} variant={"outlined"} label={a.name} disabled defaultValue={pC.attribute_values.find((val)=>val.attribute.attribute_id===a.attribute_id)?.type==="STR"?pC.attribute_values.find((val)=>val.attribute.attribute_id===a.attribute_id)?.value:pC.attribute_values.find((val)=>val.attribute.attribute_id===a.attribute_id)?.acValue?.name}/>
                                                        <Tooltip title={values.filter((val)=>val.attribute.attribute_id===a.attribute_id).length===0?"Dieses Feld wird übernommen.":"Dieses Feld wird nicht vererbt, da bereits ein individueller Wert vergeben wurde."}>
                                                            <Info></Info>
                                                        </Tooltip>

                                                    </div>
                                                }
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </>
                        )
                    }
                    {
                        type==="product"&&connectPg&&(
                            <>
                                <Divider>Geerbt aus der Produktgruppe</Divider>
                                <Grid container spacing={2}>
                                    {
                                        connectPg.attributes.map((a)=>(
                                            <Grid item xs={6}>
                                                {
                                                    a.multipleValues ? <div className={"flex w-full items-center space-x-4"}>
                                                        <Autocomplete
                                                            className={"w-full"}
                                                            disabled
                                                            readOnly
                                                            multiple
                                                            renderInput={(params)=>(<TextField {...params} label={a.name} />)}
                                                            defaultValue={a.assignedValues.map((val)=>val.type==="STR"?val.value:val.acValue?.name)}
                                                            options={a.assignedValues.map((val)=>val.type==="STR"?val.value:val.acValue?.name)}/>
                                                        <div>
                                                            <Tooltip title={inheritances.find((i)=>i.attribute_id===a.attribute_id)||inheritances.find((i)=>i.attribute_id===a.attribute_id)?.inheritance_option==="ADDITIONAL"?(inheritances.find((i)=>i.attribute_id===a.attribute_id)?.inheritance_option==="OVERWRITE"?"Es werden nur objekt-spezifische Werte genutzt":"Es werden nur Werte des nächst höheren Elements genutzt genutzt"):"Weitere Werte werden hinzugefügt."}>
                                                                <Info></Info>
                                                            </Tooltip>

                                                                    <SelectInheritance onSelection={(i)=>{

                                                                        setInheritances(inheritances.filter((val)=>val.attribute_id!==a.attribute_id).concat({
                                                                            attribute_id:a.attribute_id,
                                                                            inheritance_option:i,
                                                                            id:id
                                                                        }))
                                                                        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                                            .then(async (headers) => {
                                                                                let options:RequestInit = {
                                                                                    headers: headers,
                                                                                    method:"GET"
                                                                                }

                                                                                await fetch(myApi.url + "/attribute_values/inheritance/"+type+"/"+a.attribute_id+"/"+id+"/"+i, options)
                                                                                    .then(response => response.json())
                                                                                    .catch(error => console.log(error));
                                                                            })

                                                                    }}>
                                                                        <Tooltip title={"Vererbung ändern"} >
                                                                            <ImportExportIcon className={"cursor-pointer"}/>
                                                                        </Tooltip>
                                                                    </SelectInheritance>


                                                        </div>
                                                    </div>:<div className={"flex items-center space-x-4"}>
                                                        <TextField className={"w-full"} variant={"outlined"} label={a.name} disabled defaultValue={a.assignedValues[0]?(a.assignedValues[0].type==="STR"?a.assignedValues[0].value:a.assignedValues[0].acValue?.name):("") }/>
                                                        <Tooltip title={values.filter((val)=>val.attribute.attribute_id===a.attribute_id).length===0?"Dieses Feld wird übernommen.":"Dieses Feld wird nicht vererbt, da bereits ein individueller Wert vergeben wurde."}>
                                                            <Info></Info>
                                                        </Tooltip>

                                                    </div>
                                                }
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </>
                        )
                    }
                </div>
            </DialogContent>
            <DialogActions>
                {type!=="product_category"&&(
                    <ViewProductAttributes type={type} id={id}>
                        <>Attribute ansehen</>
                    </ViewProductAttributes>
                )}
                <Button variant={"contained"} onClick={onCloseDialog}>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    </>;

    return {
      dialog:dialog,
        interactions: {
          load:load
        }
    };

}