import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {msalInstance} from "../index";
import {AccountInfo, AuthenticationResult, InteractionRequiredAuthError} from "@azure/msal-browser";
import {useMsal} from "@azure/msal-react";
import {myApi} from "../authConfig";
import {useAttributeValuesEditor} from "./dialogs/AttributeValuesEditor";
import {getTokenResponse} from "../utils/tokenResponse";


export type ProductCategory = {
  id:string,
  name:string
}

export  function ProductCategories() {
    const { instance } = useMsal();

    const [categories, setCategories] = useState<ProductCategory[]>([]);
    const [eCategory,setECategory] = useState<ProductCategory>();
    const [nCat, setNCat] = useState("")

    const {dialog,interactions} = useAttributeValuesEditor();

    const getTokenResponse = useCallback(async ():Promise<AuthenticationResult> => {
        return new Promise<AuthenticationResult>(async (resolve)=>{
            const account = msalInstance.getActiveAccount();
            if(!account)
                throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");

            try {
                const response = await msalInstance.acquireTokenSilent({
                    scopes :["api://58eba143-b1bd-474a-86dc-966e7236cf7d/AdminSettings"],
                    account: account
                })
                resolve(response)
            }
            catch (e) {
                if (e instanceof InteractionRequiredAuthError) {
                    try {
                        const response = await msalInstance.acquireTokenPopup({
                            scopes: ["api://58eba143-b1bd-474a-86dc-966e7236cf7d/AdminSettings"],
                            account: instance.getActiveAccount() as AccountInfo
                        })
                        resolve(response)
                    } catch (e) {
                        throw Error("Response not possible");
                    }
                }
            }
        })
    },[instance])

    const loadCategories = useCallback(async ()=>{


        const response=await getTokenResponse();

        if(!response)
            throw new Error("no Resp.")

        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers
        };

        const data = await fetch(myApi.url+"/product_categories", options)
            .then(response => response.json())
            .catch(error => console.log(error));

        setCategories(data);


    },[getTokenResponse])

    useEffect( () => {
         loadCategories().catch((e)=>console.log(e))
        console.log('i fire once');



    }, [loadCategories]);

    const saveChanges = async () => {
        try {
            const response=await getTokenResponse();

            if(!response)
                return new Error("no Resp.")

            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;

            headers.append("Authorization", bearer);
            headers.append('Content-Type','application/json')

            const options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify({id:eCategory?.id,name:eCategory?.name})
            };

            await fetch(myApi.url+"/product_categories", options)
                //.then(response => response.json())
                //.catch(error => console.log(error));

            setECategory(undefined);
            await loadCategories()
        } catch (error) {
            console.error('Fehler beim Speichern der Änderungen:', error);
        }
    };




    const addCategory = async () =>{
        try {
            const response=await getTokenResponse();

            if(!response)
                return new Error("no Resp.")

            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;

            headers.append("Authorization", bearer);
            headers.append('Content-Type','application/json')

            const options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify({name:nCat})
            };

            await fetch(myApi.url+"/product_categories", options)
            //.then(response => response.json())
            //.catch(error => console.log(error));

            setNCat("");
            await loadCategories()
        } catch (error) {
            console.error('Fehler beim Erstellen einer Neuen Kat:', error);
        }
    }

    const deleteCategory = async (id:string) =>{
        try {


        const response=await getTokenResponse();

        if(!response)
            return new Error("no Resp.")

        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);
        headers.append('Content-Type','application/json')

        const options = {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify({id:id})
        };

        await fetch(myApi.url+"/product_categories", options)
        //.then(response => response.json())
        //.catch(error => console.log(error));

        setNCat("");
        await loadCategories()
    } catch (error) {
        console.error('Fehler beim Löschen einer Kat:', error);
    }

    }


    return <>
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories?.map((category) => (
                            <TableRow key={category.id}>
                                <TableCell>{category.id}</TableCell>
                                <TableCell>

                                    {eCategory?.id === category.id ? (
                                        <TextField
                                            value={eCategory.name}
                                            onChange={(e) => setECategory({ ...eCategory, name: e.target.value })}
                                        />
                                    ) : (
                                        category.name
                                    )}

                                </TableCell>
                                <TableCell className={"space-x-2"}>
                                    {eCategory?.id === category.id ? (
                                        <>
                                            <Button variant="contained" color="primary" onClick={()=>saveChanges()}>
                                                Speichern
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={() => setECategory(undefined)}>
                                                Abbrechen
                                            </Button></>
                                    ) : (
                                        <>
                                            <Button variant="contained" color="primary" onClick={() => setECategory(category)}>
                                                Bearbeiten
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={() => deleteCategory(category.id)}>
                                                Löschen
                                            </Button></>
                                    )}
                                    <Button variant="contained" color="primary" onClick={() => interactions.load("product_category",category.id)}>
                                        Attribute
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>
                                <TextField
                                    value={nCat}
                                    onChange={(e) => setNCat(e.target.value)}
                                    placeholder="Neue Kategorie"
                                />
                            </TableCell>
                            <TableCell>
                                <Button variant="contained" color="primary" onClick={addCategory}>
                                    Hinzufügen
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        {dialog}
    </>;
}