import {DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {getTokenResponse} from "../../utils/tokenResponse";
import {ProductCategory} from "../product_categories";
import {myApi} from "../../authConfig";
import {Product, useProductEditor} from "../dialogs/ProductEditor";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";

export type ProductGroup = {
    product_group_id:string,
    name:string,
    product_number:string,
    product_category: ProductCategory,
    product_category_id:string
}

export interface ProductWithProductCategory extends Product {
    product_category:ProductCategory
}

export const ProductDataGrid = ({categoryId,onSelection}:{categoryId:string|null,onSelection?:(type:"PG"|"P"|"NONE",id?:string)=>void}) => {

    const [productGroups, setProductGroups]  = useState<ProductGroup[]>([]);

    const [products, setProducts]  = useState<ProductWithProductCategory[]>([]);

    const [productGroupEquivalents, setProductGroupEquivalents] = useState<GridRowsProp>([])

    const [productEquivalents, setProductEquivalents] = useState<GridRowsProp>([])

    const [rows, setRows] = useState<GridRowsProp>([]);

    const [selId, setSelId] = useState<string>("");

    const [type, setType] = useState<"NONE"|"PG"|"P">("NONE");

    const [productRows, setProductRows] = useState<GridRowsProp>([])

    const [selPId, setSelPId] = useState<string>("");

    const [selDialogIsOpen, setSelDialogIsOpen] = useState<boolean>(false);

    const reloadP = () => {
        updateById(selId)
    }

    const {dialog:peDialog,interactions:peInteracions} = useProductEditor(reloadP);

    useEffect(() => {
        setProductGroupEquivalents(productGroups.map((pG)=>{
            return {
                id:pG.product_group_id,
                name:pG.name,
                product_number:pG.product_number,
                product_category:pG.product_category.name,
                type: "PG"
            }
        }))
    }, [productGroups]);

    useEffect(() => {
        setProductEquivalents(products.map((p)=>{
            return {
                id:p.product_id,
                name:p.name,
                product_number:p.product_number,
                product_category:p.product_category.name,
                type:"P"
            }
        }));
    }, [products]);

    useEffect(() => {

        setRows(productGroupEquivalents.concat(productEquivalents))

    }, [productGroupEquivalents, productEquivalents]);

    const load = (categoryId:string|null) => {
        if(categoryId)
        {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                .then(async (headers)=>{

                    const options = {
                        method: 'GET',
                        headers: headers
                    }

                    const lRes = await fetch(myApi.url+"/product_groups/inCategory/"+categoryId,options)
                        .then(response =>response.json())
                        .catch(error=>console.log(error));

                    setProductGroups(lRes)

                    const lRes2 = await fetch(myApi.url+"/products/inCategory/"+categoryId,options)
                        .then(response =>response.json())
                        .catch(error=>console.log(error));

                    setProducts(lRes2)

                })
        }
        else {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                .then(async (headers)=>{

                    const options = {
                        method: 'GET',
                        headers: headers
                    }

                    const lRes = await fetch(myApi.url+"/product_groups/withoutCategory",options)
                        .then(response =>response.json())
                        .catch(error=>console.log(error));

                    setProductGroups(lRes)

                    const lRes2 = await fetch(myApi.url+"/products/withoutCategory",options)
                        .then(response =>response.json())
                        .catch(error=>console.log(error));

                    setProducts(lRes2)

                })
        }
    }


    useEffect(() => {

        load(categoryId)

    }, [categoryId]);





    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Eindeutige Identifikationsnummer',
            minWidth:300
        },
        {
            field:'type',
            headerName:'Type',
            minWidth:50
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth:300
        },
        {
            field: 'product_number',
            headerName: 'Artikel-Nummer',
            minWidth:200
        },
        {
            field: 'product_category',
            headerName: 'Produktkategorie',
            minWidth:200
        }
    ];

    useEffect(() => {


        const update = () => {
            if(type==="PG")
            {
                updateById(selId)
            }
        }
        update()
    }, [selId,type]);

    const updateById = (id:string) => {
        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
            .then(async (headers)=>{

                const options = {
                    method: 'GET',
                    headers: headers
                }

                const lRes = await fetch(myApi.url+"/product_groups/productsFrom/"+id,options)
                    .then(response =>response.json())
                    .catch(error=>console.log(error));

                setProductRows(lRes.map((lP:{product_id:string,name:string,product_number:string})=> {
                    return {
                        id:lP.product_id,
                        name:lP.name,
                        product_number:lP.product_number
                    }
                }))

            })
    }



    const onSelectionChange= (a:GridRowSelectionModel) => {


        if(onSelection)
        {
            if(a.length!==0)
            {
               if(rows.find((row)=>row.id===a[0])?.type==='PG'&&typeof a[0]==='string') {
                   setType("PG");
                   setSelId(a[0])

                   onSelection("PG", a[0])
               }



               if(rows.find((row)=>row.id===a[0])?.type==='P'&&typeof a[0]==='string')
               {
                   setType("P");
                   setSelId(a[0])
                   onSelection("P", a[0])
               }





            }
            else
            {
                onSelection("NONE")
                setType("NONE");
            }
        }

    }

    const reload = () => {
        load(categoryId)
    }


    const ret = (<>

        <DataGrid columns={columns} rows={rows} initialState={{
            columns: {
                columnVisibilityModel: {
                    id:false,
                    type:false
                }
            }
        }}

        onRowSelectionModelChange={onSelectionChange}
        />

        {
            type ==="PG" && (<>
                <div className={"flex"}>
                    <Typography  className={"flex-grow"} variant={'h5'}>Produkte in der Produktgruppe</Typography>
                    <Button color={"primary"} onClick={()=>{
                        if(selPId==="") {
                            setSelDialogIsOpen(true)
                        }
                        else {
                            peInteracions.loadAndOpen(selPId)
                        }
                    }}>{selPId===""?"Hinzufügen":"Bearbeiten"}</Button>
                </div>
                <DataGrid columns={[
                    {
                        field: 'id',
                        headerName: 'Eindeutige Identifikationsnummer',
                        minWidth:300
                    },
                    {
                        field: 'name',
                        headerName: 'Name',
                        minWidth:300
                    },
                    {
                        field: 'product_number',
                        headerName: 'Artikel-Nummer',
                        minWidth:200
                    }
                ]} rows={productRows}

                          initialState={{
                              columns: {
                                  columnVisibilityModel: {
                                      id:false
                                  }
                              }
                          }}

                          onRowSelectionModelChange={(a)=>{
                              if(a.length===0)
                              {
                                  setSelPId("");
                              }
                              else if(typeof a[0] === 'string')
                              {
                                  setSelPId(a[0])
                              }
                          }}
                />
                {peDialog}
            </>)
        }
        <Dialog open={selDialogIsOpen}>
            <DialogTitle>
                Produkt zu Produktgruppe hinzufügen
            </DialogTitle>
            <DialogContent>
                Bitte wähle aus, ob du ein bereits bestehendes Einzelprodukt hinzufügen möchtest oder ein neues Produkt anlegen willst.
            </DialogContent>
            <DialogActions>
                <Button color={"error"} onClick={()=>setSelDialogIsOpen(false)}>
                    Abbrechen
                </Button>
                <Button color={"primary"} variant={"contained"} onClick={()=>{
                    setSelDialogIsOpen(false)
                    getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                        .then(async (headers)=>{

                            const options = {
                                method: 'GET',
                                headers: headers
                            }

                            const lRes = await fetch(myApi.url+"/product_groups/byId/"+selId,options)
                                .then(response =>response.json())
                                .catch(error=>console.log(error));

                            peInteracions.openWithGroup(lRes,lRes.product_group_id)


                        })
                }}>
                    Neues Produkt
                </Button>
            </DialogActions>
        </Dialog>
    </>);

    return {
        grid: ret,
        reload:reload
    }

}