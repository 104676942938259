import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem, ListItemButton
} from "@mui/material";
import React, {ReactElement, useState} from "react";
import {ProductCategory} from "../product_categories";
import {getTokenResponse} from "../../utils/tokenResponse";
import {myApi} from "../../authConfig";

export const SelectProductCategory = ({children,onSelection}:{children?:ReactElement|string,onSelection?:(option:ProductCategory)=>void}) : ReactElement => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const [pCs,setPCs] = useState<ProductCategory[]>([]);

    const openDialog = () => {

        if(pCs.length===0)
        {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                .then(async (headers)=>{
                    const options = {
                        method: "GET",
                        headers:headers
                    }

                    const data2 = await fetch(myApi.url+"/product_categories", options)
                        .then(response => response.json())
                        .catch(error => console.log(error));


                    setPCs(data2)



                })
        }

        setDialogIsOpen(true);
    }

    const onCloseDialog = () => {
        setDialogIsOpen(false)
    }

    const onCommit = (option:ProductCategory) => {
        onCloseDialog()

        if(onSelection)
            onSelection(option)
    }


    return(
        <>
            <div onClick={openDialog} className={"cursor-pointer w-full"}>
                {children}
            </div>
            <Dialog open={dialogIsOpen} onClose={onCloseDialog}>
                <DialogTitle>Produktkategorie Auswählen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte wähle eine Produktkategorie aus.
                    </DialogContentText>
                    <List>
                        {
                            pCs.map((pC)=>(
                                    <ListItem disableGutters key={pC.id}>
                                        <ListItemButton onClick={()=>onCommit(pC)}>
                                            {pC.name}
                                        </ListItemButton>
                                    </ListItem>
                                )
                            )
                        }

                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseDialog} color="error">
                        Abbrechen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}