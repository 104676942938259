import {Configuration} from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        clientId: '58eba143-b1bd-474a-86dc-966e7236cf7d',
        authority: 'https://login.microsoftonline.com/9933de9d-60c9-4d6e-bb3a-3f9589a986fc/',
        redirectUri: "https://pm.cr-lederwaren.de",//https://pm.cr-lederwaren.de
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: true,

        //protocolMode:"AAD"
    }
}

export const myApi = {
    url: "https://api-pm.cr-lederwaren.de"
}