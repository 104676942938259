import {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography} from "@mui/material";
import {AiChat} from "../../types/AiChat";
import {getTokenResponse} from "../../utils/tokenResponse";
import {myApi} from "../../authConfig";

export const useKiDialog = (rel?:()=>void) => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const [role,setRole] = useState<string>("");
    const [nM,setNM] = useState<string>("");

    const [chat,setChat] = useState<AiChat>({
        name:"",
        chat_id:"",
        messages:[]
    });

    const onCloseDialog = () => {
        setDialogIsOpen(false);
        setChat({
            name:"",
            chat_id:"",
            messages:[]
        })
    }

    const open = () => {
        setDialogIsOpen(true)
    }

    const load = (id:string) => {
        setDialogIsOpen(true);

        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
            .then(async (headers)=>{

                const options = {
                    method: "GET",
                    headers: headers
                };

                const d = await fetch(myApi.url+"/ki/"+id,options)
                    .then(response => response.json())
                    .catch(error => console.log(error))

                setChat(d)

                if(d.messages[d.messages.length-1].role!=="assistant")
                {
                    setTimeout(()=>reload(d),2000)
                }
            })

    }

    const reload = (c:AiChat=chat) => {
        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
            .then(async (headers)=>{

                const options = {
                    method: "GET",
                    headers: headers
                };

                const d:AiChat= await fetch(myApi.url+"/ki/"+c.chat_id,options)
                    .then(response => response.json())
                    .catch(error => console.log(error))



                setChat(d)

                if(d.messages[d.messages.length-1].role!=="assistant")
                {
                    setTimeout(()=>{
                        reload(c)
                    },2000)
                }


            })
    }

    const save = () => {

        if(chat.chat_id==="")
        {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                .then(async (headers)=>{

                    headers.append('Content-Type', 'application/json')

                    const options = {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify({
                            name:chat.name,
                            role:role,
                            message:nM
                        })
                    };

                    const d = await fetch(myApi.url+"/ki/",options)
                        .then(response => response.json())
                        .catch(error => console.log(error))

                    setChat(d.data)

                    if(rel)
                        rel()

                    setTimeout(()=>{
                        reload(d.data)
                    },2000)


                })
        }
        else {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                .then(async (headers)=>{

                    headers.append('Content-Type', 'application/json')

                    const options = {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify({
                            name:chat.name,
                            message:nM
                        })
                    };

                    const d = await fetch(myApi.url+"/ki/"+chat.chat_id,options)
                        .then(response => response.json())
                        .catch(error => console.log(error))

                    setChat(d.data)


                    setTimeout(reload,2000)


                })
        }

        setNM("")
    }

    const dialog = <>
        <Dialog open={dialogIsOpen} onClose={onCloseDialog} fullWidth maxWidth={"lg"}>
            <DialogTitle>Chat-Protokoll</DialogTitle>
            <DialogContent className={""}>
                <div className={"space-y-4 mt-2"}>
                    <TextField  fullWidth variant={"outlined"} label={"Name"} value={chat.name} onChange={(e)=>{setChat({...chat,name:e.target.value})}}/>
                    {
                        chat.chat_id===""&&(
                            <TextField variant={"outlined"} label={"Wer soll die KI sein?"} value={role} onChange={(e)=>{setRole(e.target.value)}}/>
                        )
                    }
                    {
                        chat.messages&&chat.messages.map((msg)=>(
                            <>
                                <Divider></Divider>
                                <div>
                                    <Typography variant={"h6"}>Rolle: {msg.role}</Typography>
                                    {msg.content.split("\n").map((m)=><>
                                        {
                                            m===""?<br/>:<Typography>{m}</Typography>
                                        }
                                    </>)
                                    }
                                </div></>
                        ))
                    }
                    {
                        (chat.messages&&(chat.messages.length===0||(chat.messages[chat.messages.length-1].role==="assistant")))&&(
                            <div className={"space-y-3"}>
                                <Divider></Divider>
                                <TextField label={"Neue Eingabe"} value={nM} onChange={(e)=>{setNM(e.target.value)}} minRows={3} multiline className={"w-full"}/>
                                <Button variant={"contained"} onClick={save}>Absenden</Button>
                            </div>

                        )
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={onCloseDialog}>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    </>;

    return {dialog:dialog,interactions:{load:load,open:open}}

}