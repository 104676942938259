import {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import {BulletPointGroup} from "./BulletPointGroupsDialog";
import {getTokenResponse} from "../../utils/tokenResponse";
import {myApi} from "../../authConfig";
import {Cancel, Delete, Edit, Save} from "@mui/icons-material";

export interface BulletPointGroupWithPoints extends BulletPointGroup {
    bullet_points: BulletPoint[]
}

export type BulletPoint = {
    position:number,
    value:string,
    bullet_point_id:string
}

export const useBulletPointGroupDialog = () => {
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [group,setGroup] = useState<BulletPointGroupWithPoints>()
    const [nVP,setNVP] = useState<string>("0");
    const [nVV,setNVV] = useState<string>("");
    const [eNVP,setENVP] = useState<string>("0");
    const [eNVV,setENVV] = useState<string>("");
    const [pId,setPId] = useState("")
    const [editId,setEditId] = useState("");

    const onCloseDialog = () => {
        setDialogIsOpen(false);
        setNVV("")
        setNVP("0")
        setEditId("")
    }

    const load = (pId:string,gId:string) => {

        setPId(pId)
        setDialogIsOpen(true)
        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
            .then(async (headers)=>{

                const options = {
                    method: "GET",
                    headers: headers
                };

                const data: BulletPointGroupWithPoints = await fetch(myApi.url+"/products/byId/"+pId+"/bullet_group/"+gId,options)
                    .then(response => response.json())
                    .catch(error => console.log(error));

                setGroup(data)
                console.log(data)

            })
    }

    const dialog = <>
        <Dialog open={dialogIsOpen} fullWidth maxWidth={"lg"} onClose={onCloseDialog}>
            <DialogTitle>Bulletpoint-Gruppe</DialogTitle>
            <DialogContent>
                <div className={"mt-2"}>
                    {
                        group && group.bullet_points.length===0 && (
                            <Button variant={"contained"} onClick={()=>{
                                getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                    .then(async (headers)=>{
                                        headers.append('Content-Type', 'application/json')

                                        const options = {
                                            method: "POST",
                                            headers: headers,
                                            body: JSON.stringify({
                                                count:5
                                            })
                                        };

                                        await fetch(myApi.url+"/products/byId/"+pId+"/bullet_group/"+group.bullet_point_group_id+"/genKi/",options)
                                            .then(response => response.json())
                                            .catch(error => console.log(error));

                                    })
                            }}>
                                5 Bullet-Punkte per API generieren
                            </Button>
                        )
                    }
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Wert</TableCell>
                                <TableCell>Aktionen</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                group && group.bullet_points.sort((a,b)=>a.position-b.position).map((point)=>(
                                    <TableRow>
                                        <TableCell>{point.bullet_point_id}</TableCell>
                                        <TableCell>{editId===point.bullet_point_id?<TextField variant={"outlined"} value={eNVP} inputProps={{inputMode:"numeric"}} onChange={(e)=>setENVP(e.target.value)} />:point.position}</TableCell>
                                        <TableCell>{editId===point.bullet_point_id?<TextField variant={"outlined"} value={eNVV}  onChange={(e)=>setENVV(e.target.value)} />:point.value}</TableCell>
                                        <TableCell>
                                            <div className={"flex space-x-3"}>
                                                <Delete
                                                    className={"cursor-pointer"}
                                                    onClick={()=>{
                                                        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                            .then(async (headers)=>{
                                                                headers.append('Content-Type', 'application/json')

                                                                const options = {
                                                                    method: "DELETE",
                                                                    headers: headers
                                                                };

                                                                const data = await fetch(myApi.url+"/products/byId/"+pId+"/bullet_group/"+group.bullet_point_group_id+"/bullet/"+point.bullet_point_id,options)
                                                                    .then(response => response.json())
                                                                    .catch(error => console.log(error));

                                                                if(data.message==="success")
                                                                {
                                                                    setGroup({...group,bullet_points:group.bullet_points.filter((f)=>f.bullet_point_id!==point.bullet_point_id)})
                                                                }

                                                            })
                                                    }}
                                                />
                                                {
                                                    editId===point.bullet_point_id? <>
                                                        <Save
                                                            className={"cursor-pointer"}
                                                            onClick={()=>{
                                                                getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                                    .then(async (headers)=>{
                                                                        headers.append('Content-Type', 'application/json')

                                                                        const options = {
                                                                            method: "POST",
                                                                            headers: headers,
                                                                            body: JSON.stringify({
                                                                                value:eNVV,
                                                                                position:parseInt(eNVP)
                                                                            })
                                                                        };

                                                                        const data = await fetch(myApi.url+"/products/byId/"+pId+"/bullet_group/"+group.bullet_point_group_id+"/bullet/"+point.bullet_point_id,options)
                                                                            .then(response => response.json())
                                                                            .catch(error => console.log(error));

                                                                        if(data.message==="success")
                                                                        {
                                                                            setGroup(data.data)
                                                                            setEditId("");
                                                                            setENVP("0")
                                                                            setENVV("")
                                                                        }

                                                                    })
                                                            }}

                                                        />
                                                        <Cancel
                                                            className={"cursor-pointer"}
                                                            onClick={()=>{
                                                                setEditId("")
                                                                setENVP("0")
                                                                setENVV("")
                                                            }}
                                                        />
                                                    </>:<>
                                                        <Edit
                                                            className={"cursor-pointer"}
                                                            onClick={()=>{
                                                                setEditId(point.bullet_point_id)
                                                                setENVV(point.value);
                                                                setENVP(""+point.position)
                                                            }}
                                                        />
                                                    </>
                                                }
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))

                            }
                            {
                                group && (
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell><TextField variant={"outlined"} value={nVP} inputProps={{inputMode:"numeric"}} onChange={(e)=>setNVP(e.target.value)} /> </TableCell>
                                        <TableCell className={"min-w-full"}><TextField value={nVV} onChange={(e)=>setNVV(e.target.value)} variant={"outlined"} multiline/> </TableCell>
                                        <TableCell>
                                            <Save
                                                className={"cursor-pointer"}
                                                onClick={()=>{
                                                    getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                        .then(async (headers)=>{
                                                            headers.append('Content-Type', 'application/json')

                                                            const options = {
                                                                method: "POST",
                                                                headers: headers,
                                                                body: JSON.stringify({
                                                                    value:nVV,
                                                                    position:parseInt(nVP)
                                                                })
                                                            };

                                                            const data = await fetch(myApi.url+"/products/byId/"+pId+"/bullet_group/"+group.bullet_point_group_id+"/bullet/",options)
                                                                .then(response => response.json())
                                                                .catch(error => console.log(error));

                                                            if(data.message==="success")
                                                            {
                                                                setGroup(data.data)
                                                                setNVP("0");
                                                                setNVV("")
                                                            }

                                                        })
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            }

                        </TableBody>
                    </Table>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={onCloseDialog}>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    </>;

    return {
        dialog:dialog,
        interactions: {
            load:load
        }
    }
}