import React from 'react';
import {Route, Routes} from "react-router-dom";
import {IPublicClientApplication, NavigationClient} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {Root} from "./pages/Root";
import {Button, Typography} from "@mui/material";
import {AuthenticatedRoutes} from "./router/autenticatedRoutes";
import {ProductCategories} from "./components/product_categories";
import {Products} from "./components/products";
import Test from "./components/test";
import {Attributes} from "./pages/Attributes";
import {Ki} from "./pages/Ki";

type AppProps = {
  pca: IPublicClientApplication;
};


function App({pca}:AppProps) {

  const navigationClient = new NavigationClient();
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <div className={"min-w-full "}>
        <div className={"min-w-full"}>
          <Routes>
            <Route path={"/"} element={<Root/>}></Route>
            <Route path={"/intern/"} element={<AuthenticatedRoutes/>}>
              <Route path={"dashboard"} element={<>Haallo</>}/>
              <Route path={"product_categories"} element={<ProductCategories/>}/>
              <Route path={"products"} element={<Products/>}/>
              <Route path={"test"} element={<Test/>}/>
              <Route path={"attributes"} element={<Attributes/>}/>
              <Route path={"ai"} element={<Ki/>}/>
            </Route>
          </Routes>
        </div>
        <div className={"p-2"}>
          <Typography>Ein interner Service der Einzelunternehmung Cornelia Riep, Frankenstr. 288, 45134 Essen - <a className={"underline text-blue-700"} href={"https://cr-lederwaren.de/impressum.html"}>Impressum</a></Typography>
        </div>
      </div>


    </MsalProvider>
  );
}

export default App;
