import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Typography} from "@mui/material";
import React, {useState} from "react";
import {useCategoryTreeWithEditOptions} from "../hooks/useCategoryTreeWithEditOptions";
import {ProductDataGrid} from "./data_grids/ProductDataGrid";
import {useProductGroupEditor} from "./dialogs/ProductGroupEditor";
import {useProductEditor} from "./dialogs/ProductEditor";

export const Products = () => {

    const {categoryBrowser,categoryTreeModal,interactions} = useCategoryTreeWithEditOptions();

    const [selType,setSelType] = useState<"PG"|"P"|"NONE">("NONE");

    const onSel = (type:"PG"|"P"|"NONE",id?:string) => {

        setSelType(type)
        if(id)
            setEditId(id)
    }

    const {grid,reload} = ProductDataGrid({categoryId:interactions.browserInteractions.selectedCategoryId,onSelection:onSel} )

    const {dialog, interactions:int} = useProductGroupEditor(reload);

    const {dialog:dialogP, interactions:intP} = useProductEditor(reload);

    const [editId, setEditId] = useState<string>("");

    const [chooseDialogIsOpen, setChooseDialogIsOpen] = useState<boolean>(false);





    const onButtonClick = () => {
        if(selType==="PG")
            int.loadAndOpen(editId)
        else if(selType==="P")
            intP.loadAndOpen(editId)
        else if(selType==="NONE")
            setChooseDialogIsOpen(true)
    }


    return <div className={"m-1"}>

            <Grid container spacing={2} p={2} className={"max-w-full"} direction={"row"}>
                <Grid item xs={12} md={"auto"}>
                    <Paper elevation={3} className={'p-2 space-y-2'}>
                        <Typography variant={'h5'}>Kategorien</Typography>
                        {categoryBrowser}
                        <div>
                            <div className={'space-y-2 flex flex-col max-w-fit'}>
                                <Button variant="contained" color="primary" onClick={interactions.openCategoryCreationDialog}>
                                    Kategorie hinzufügen
                                </Button>
                                <Button variant="contained" color="primary" onClick={interactions.deleteSelectedCategory}>
                                    Löschen
                                </Button>
                                <Button variant={!interactions.moveSelectedCategory.moveIsActive ? 'contained' : 'outlined'} color="primary"
                                        onClick={interactions.moveSelectedCategory.toggleMove}>
                                    Verschieben
                                </Button>
                                <Button variant="contained" color="primary" onClick={interactions.openEditDialog}>
                                    Bearbeiten
                                </Button>
                            </div>
                            {categoryTreeModal}
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} md className={"max-w-full"}>
                    <Paper elevation={3} className={"p-2 space-y-2"}>
                        <div className={"flex"}>
                            <Typography  className={"flex-grow"} variant={'h5'}>Produkte und Produktgruppen in der Kategorie {interactions.browserInteractions.getCategoryName(interactions.browserInteractions.selectedCategoryId)}</Typography>
                            <Button color={"primary"} onClick={onButtonClick}>{selType==="NONE" ? "Erstellen" : "Bearbeiten"}</Button>
                        </div>
                        {grid}
                    </Paper>
                </Grid>
            </Grid>
        {dialog}
        {dialogP}
        <Dialog open={chooseDialogIsOpen}>
            <DialogTitle>
                Produkt oder Produktgruppe hinzufügen
            </DialogTitle>
            <DialogContent>
                Bitte wähle aus, ob du ein Produkt oder eine Produktgruppe erstellen willst.
            </DialogContent>
            <DialogActions>
                <Button color={"error"} onClick={()=>setChooseDialogIsOpen(false)}>
                    Abbrechen
                </Button>
                <Button color={"primary"} variant={"contained"} onClick={()=>{
                    setChooseDialogIsOpen(false)
                    intP.open(interactions.browserInteractions.getCategoryById(interactions.browserInteractions.selectedCategoryId));
                }}>
                    Produkt
                </Button>
                <Button color={"primary"} variant={"contained"} onClick={()=>{
                    setChooseDialogIsOpen(false)
                    int.open(interactions.browserInteractions.getCategoryById(interactions.browserInteractions.selectedCategoryId));
                }}>
                    Produktgruppe
                </Button>
            </DialogActions>
        </Dialog>
    </div>

}

