import {Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {AiChat} from "../types/AiChat";
import {useEffect, useState} from "react";
import {getTokenResponse} from "../utils/tokenResponse";
import {myApi} from "../authConfig";
import {Delete, Edit} from "@mui/icons-material";
import {useKiDialog} from "../components/dialogs/KiDialog";

export const Ki = () => {

    const [chats, setChats] =useState<AiChat[]>([]);
    const {dialog,interactions} = useKiDialog(()=>{
        getData()
    });

    useEffect(() => {
        getData()
    }, []);

    const getData = () => {
        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
            .then(async (headers)=>{

                const options = {
                    method: "GET",
                    headers: headers
                };

                const data: AiChat[] = await fetch(myApi.url+"/ki",options)
                    .then(response => response.json())
                    .catch(error => console.log(error));

                setChats(data)

            })
    }

    return (<>
        <div className={"m-1"}>
            <div className={"p-4"}>
                <Paper elevation={3} className={"p-2 space-y-2"}>
                    <div className={"flex"}>
                        <Typography className={"flex-grow"}>Chat-Protokolle</Typography>
                        <Button onClick={()=>{
                            interactions.open()
                        }}>
                            Erstellen
                        </Button>
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Aktionen</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                chats.map((chat)=>(
                                    <TableRow key={chat.chat_id}>
                                        <TableCell>{chat.chat_id}</TableCell>
                                        <TableCell>{chat.name}</TableCell>
                                        <TableCell className={"flex space-x-2"}>
                                            <Delete className={"cursor-pointer"}
                                                    onClick={()=>{
                                                        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                            .then(async (headers)=>{

                                                                const options = {
                                                                    method: "DELETE",
                                                                    headers: headers
                                                                };

                                                                await fetch(myApi.url+"/ki/"+chat.chat_id,options)
                                                                    .then(response => response.json())
                                                                    .catch(error => console.log(error));

                                                                setChats(chats.filter((c)=>c.chat_id!==chat.chat_id))

                                                            })
                                                    }}
                                            />
                                            <Edit className={"cursor-pointer"}
                                                    onClick={()=>{
                                                        interactions.load(chat.chat_id)
                                                    }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Paper>
            </div>

        </div>
        {dialog}
    </>)
}