import React, {ReactElement, useEffect, useState} from "react";
import {
    Alert,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormGroup,
    Grid,
    InputLabel, MenuItem, Select, Snackbar,
    TextField
} from "@mui/material";
import {Category, CategoryPrisma} from "../../types/Category";
import {ProductGroupWithCategories} from "./ProductGroupEditor";
import {ProductCategory} from "../product_categories";
import {getTokenResponse} from "../../utils/tokenResponse";
import {myApi} from "../../authConfig";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {SelectCategory} from "./SelectCategory";
import {SelectInheritance} from "./SelectInheritance";
import {useAttributeValuesEditor} from "./AttributeValuesEditor";
import {useBulletPointGroupsDialog} from "./BulletPointGroupsDialog";
import {useProductTextDialog} from "./ProductTextDialog";
import {useProductImageDialog} from "./ProductImageDialog";

type ProductEditorReturns = {
    dialog: ReactElement,
    interactions: ProductEditorInteractions
}

type ProductEditorInteractions = {
    open:(lCat?:Category|null)=>void,
    load:(product_id:string)=>void,
    loadAndOpen:(product_id:string)=>void,
    openWithGroup:(lGroup:ProductGroupWithCategories,lGroup_id:string)=>void
}

export interface Product {
    product_id:string,
    name:string,
    product_number:string,
    category_inheritance_option: "OVERWRITE"|"ADDITIONAL"|"INHERIT",
    categories: {
        category: CategoryPrisma
    }[],
    product_category_id: null|string,
    product_category_inheritance_option: "OVERWRITE"|"INHERIT",
    product_group: ProductGroupWithCategories|null,
    product_group_id: string|null,
}

export const useProductEditor = (reload?:()=>void):ProductEditorReturns => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false)

    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);

    const [snackbarIsOpen, setSnackbarIsOpen] = useState<boolean>(false);

    const [snackbarMessage, setSnackbarMessage] = useState<string>("");

    const [selectedCategoriesForDelete, setSelectedCategoriesForDelete] = useState<string[]>([]);

    const {dialog:dialogA,interactions} = useAttributeValuesEditor();

    const {dialog:bpDialog, interactions:bpInt} = useBulletPointGroupsDialog();

    const {dialog:tDialog, interactions:tInt} = useProductTextDialog();

    const {dialog:iDialog, interactions:iInt} = useProductImageDialog();

    const defP:Product = {
        name:"",
        product_number:"",
        category_inheritance_option:"INHERIT",
        product_category_inheritance_option:"INHERIT",
        product_group: null,
        product_id:"",
        categories:[],
        product_category_id:null,
        product_group_id:null
    }

    const [product,setProduct] = useState<Product>(defP);

    const reset = () => {
        setProduct(defP)
        setSelectedCategoriesForDelete([]);
    }
    const onCloseDialog = () => {
        setDialogIsOpen(false);
        reset()
    }

    const open = (lCat?:Category|null, lAP:Product =product) => {
        setDialogIsOpen(true)

        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Write"])
            .then(async (headers)=>{
                const options = {
                    method: "GET",
                    headers:headers
                }

                const data2 = await fetch(myApi.url+"/product_categories", options)
                    .then(response => response.json())
                    .catch(error => console.log(error));


                setProductCategories(data2)



            })

        let nP:Product = lAP;

        if(nP.product_id===""&&nP.product_group_id===null)
        {
            nP = {
                ...lAP,
                category_inheritance_option: "ADDITIONAL",
                product_category_inheritance_option: "OVERWRITE"
            }

            if(lCat&&lCat.category_id)
            {
                nP = {
                    ...nP,
                    categories: [{
                        category: {
                            category_id:lCat.category_id,
                            name:lCat.name,
                            parent_category_id:lCat.parent_category_id
                        }
                    }]
                }


            }


        }
        setProduct(nP)


    }

    const openWithGroup = (lGroup:ProductGroupWithCategories,lGroup_id:string) => {

        open(null,{
            ...product,
            product_group_id:lGroup_id,
            product_group:lGroup
        })



    }

    useEffect(() => {
        console.log(product)
    }, [product]);

    const onCategoryAdd = (lCat_id:string|null,lCat?:Category|null) => {

        let skip = false;

        if(product.category_inheritance_option==="INHERIT")
        {
            setSnackbarMessage("Wegen der aktuellen Vererbungsoption können Kategorien nur in der Produktgruppe gepflegt werden.")
            setSnackbarIsOpen(true)
            return;
        }

        if(!lCat_id||!lCat)
        {
            setSnackbarMessage("Es darf nicht die Basiskategorie ausgewählt werden.")
            setSnackbarIsOpen(true)
            return;
        }

        product.categories.forEach((fCat)=>{
            if(fCat.category.category_id === lCat_id)
            {
                setSnackbarMessage(`Das Produkt ist bereits in der Kategorie ${fCat.category.name}.`)
                setSnackbarIsOpen(true)
                skip=true;
                return;
            }
        })

        product.product_group_id!==null&&product.category_inheritance_option!=="OVERWRITE"&& product.product_group?.categories.forEach((fCat)=>{
            if(fCat.category.category_id === lCat_id)
            {
                setSnackbarMessage(`Das Produkt ist bereits in der Kategorie ${fCat.category.name}.`)
                setSnackbarIsOpen(true)
                skip=true;
                return;
            }
        })

        if(skip)
            return;

        setProduct(
            {
                ...product,
                categories: product.categories.concat({
                    category: {
                        category_id:lCat_id,
                        name: lCat.name,
                        parent_category_id: lCat.parent_category_id
                    }
                })
            }
        )

    }

    const deleteCategories = () => {

        setProduct({...product, categories: product.categories.filter((lCat)=> !selectedCategoriesForDelete.includes(lCat.category.category_id))})

        setSelectedCategoriesForDelete([]);
    }

    const save = () => {

        if(product.product_id!== "")
        {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Write"])
                .then(async (headers)=> {
                    headers.append('Content-Type', 'application/json')

                    const options = {
                        method: "PUT",
                        headers:headers,
                        body: JSON.stringify({
                            name: product.name,
                            product_number: product.product_number,
                            category_inheritance_option:product.category_inheritance_option,
                            product_category_id: product.product_category_inheritance_option==="INHERIT"?null:product.product_category_id,
                            categories: product.category_inheritance_option==="INHERIT" ? [] : product.categories.map((lC) => {
                                return lC.category.category_id
                            }),
                            product_category_inheritance_option: product.product_category_inheritance_option,
                            product_group_id: product.product_group_id
                        })
                    }



                    await fetch(myApi.url+"/products/"+product.product_id, options)
                        .then(response => response.json())
                        .catch(error => console.log(error));

                    onCloseDialog()
                    if(reload)
                        reload()
                })
        }
        else
        {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Write"])
                .then(async (headers)=>{
                    headers.append('Content-Type', 'application/json')

                    const options = {
                        method: "POST",
                        headers:headers,
                        body: JSON.stringify({
                            name: product.name,
                            product_number: product.product_number,
                            category_inheritance_option:product.category_inheritance_option,
                            product_category_id: product.product_category_inheritance_option==="INHERIT"?null:product.product_category_id,
                            categories: product.category_inheritance_option==="INHERIT" ? [] : product.categories.map((lC) => {
                                return lC.category.category_id
                            }),
                            product_category_inheritance_option: product.product_category_inheritance_option,
                            product_group_id: product.product_group_id
                        })
                    }



                    await fetch(myApi.url+"/products", options)
                        .then(response => response.json())
                        .catch(error => console.log(error));

                    onCloseDialog()
                    if(reload)
                        reload()


                })
        }

    }

    const load = (product_id:string) =>{

        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Write"])
            .then(async (headers)=>{
                const options = {
                    method: "GET",
                    headers:headers
                }


                const data = await fetch(myApi.url+"/products/byId/"+product_id, options)
                    .then(response => response.json())
                    .catch(error => console.log(error));

                setProduct(data)


            })
    }

    const loadAndOpen = (product_id:string) => {
        load(product_id);
        open();

    }

    useEffect(() => {
        console.log(product.product_category_inheritance_option==="OVERWRITE"||product.product_group_id===null?product.product_category_id:product.product_group?.product_category_id)
    }, [product]);

    const dialog = (<>
        <Dialog open={dialogIsOpen} onClose={onCloseDialog} fullWidth maxWidth={"lg"}>
            <DialogTitle>Produkteditor</DialogTitle>
            <form onSubmit={(e)=> {
                e.preventDefault()
                save()
            }}>
                <DialogContent>
                    <FormControl className={"w-full"}>
                        <FormGroup className={"m-2 space-y-2"}>
                            <Grid container spacing={2} className={"w-full"}>
                                <Grid item xs={12}>
                                    <Divider>Stammdaten</Divider>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required className={"w-full"} label={"Produktname"} value={product.name} onChange={(e)=>setProduct({...product,name:e.target.value})}></TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField required label={"Produktnummer"} value={product.product_number} onChange={(e)=>setProduct({...product,product_number:e.target.value})}></TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={"flex items-center space-x-2"}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink id="demo-simple-select-label">Produktkategorie</InputLabel>
                                            <Select
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={product.product_category_inheritance_option==="OVERWRITE"||product.product_group_id===null?product.product_category_id:product.product_group?.product_category_id}
                                                label="Produktkategorie"
                                                disabled={product.product_category_inheritance_option==="INHERIT"&&product.product_group_id!==null}
                                                onChange={(e)=>{
                                                    if(product.product_category_inheritance_option==="OVERWRITE"||product.product_group_id===null)
                                                    {
                                                        setProduct({
                                                            ...product,
                                                            product_category_id:e.target.value
                                                        })
                                                    }
                                                }}
                                            >

                                                {
                                                    productCategories.map((productCategory)=>
                                                        (
                                                            <MenuItem value={productCategory.id}>{productCategory.name}</MenuItem>
                                                        )
                                                    )
                                                }



                                            </Select>
                                        </FormControl>
                                        <ImportExportIcon  className={"cursor-pointer"} color={product.product_category_inheritance_option==="INHERIT"&&product.product_group_id!==null ? "primary" : "disabled"}

                                                           onClick={()=>{
                                                               if(!product.product_group_id)
                                                               {
                                                                   setSnackbarIsOpen(true);
                                                                   setSnackbarMessage("Damit die Vererbung verändert werden kann, muss eine Produktgruppe verknüpft sein.")
                                                                   return;
                                                               }

                                                               if(product.product_category_inheritance_option==="OVERWRITE")
                                                                   setProduct({...product, product_category_inheritance_option:"INHERIT"})
                                                               else
                                                                   setProduct({...product, product_category_inheritance_option:"OVERWRITE"})
                                                           }}

                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider>Kategorien</Divider>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink id="cat">Kategorien</InputLabel>
                                        <Select

                                            native
                                            multiple
                                            labelId="cat"
                                            label="Kategorien"
                                            value={selectedCategoriesForDelete}
                                            //@ts-ignore
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                const { options } = event.target;
                                                const value: string[] = [];
                                                for (let i = 0, l = options.length; i < l; i += 1) {
                                                    if (options[i].selected) {
                                                        value.push(options[i].value);
                                                    }
                                                }
                                                setSelectedCategoriesForDelete(value);
                                            }}

                                        >

                                            {

                                                product.category_inheritance_option!=="INHERIT"&&product.categories.map((lCat)=>
                                                    (
                                                        <option value={lCat.category.category_id} key={lCat.category.category_id}>{lCat.category.name}</option>
                                                    )
                                                )
                                            }
                                            {
                                                product.product_group_id!==null&&product.category_inheritance_option!=="OVERWRITE"&&product.product_group?.categories.map((lCat)=>(
                                                    <option disabled value={lCat.category.category_id} key={lCat.category.category_id}>{lCat.category.name} (geerbt)</option>
                                                ))
                                            }

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={"flex flex-col space-y-2"}>
                                        <SelectCategory title={"Kategorie hinzufügen"} onSelection={onCategoryAdd}>
                                            Kategorie hinzufügen
                                        </SelectCategory>
                                        <Button onClick={deleteCategories} color={"error"} disabled={selectedCategoriesForDelete.length===0} variant={"contained"} >
                                            Ausgewählte löschen
                                        </Button>
                                        <SelectInheritance onSelection={(option)=>{
                                            setProduct({
                                                ...product,
                                                category_inheritance_option:option
                                            })
                                        }}>
                                            <Button color={"primary"} variant={"contained"} className={"w-full"} >
                                                Vererbung auswählen
                                            </Button>
                                        </SelectInheritance>
                                    </div>
                                </Grid>
                                <Grid item>
                                        <Button variant={"contained"} onClick={()=>{interactions.load("product",product.product_id)}}>
                                            Attribute bearbeiten
                                        </Button>

                                </Grid>
                                {
                                    product.product_id!=="" &&(<>
                                        <Grid item>
                                            <Button variant={"contained"} onClick={()=>{bpInt.load(product.product_id)}}>
                                                Bulletgruppen bearbeiten
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant={"contained"} onClick={()=>{tInt.load(product.product_id)}}>
                                                Texte bearbeiten
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant={"contained"} onClick={()=>{iInt.load(product.product_id)}}>
                                                Produktbilder
                                            </Button>
                                        </Grid>
                                    </>)
                                }

                            </Grid>


                        </FormGroup>
                    </FormControl>
                    <DialogActions>
                        <Button onClick={onCloseDialog} color="error">
                            Abbrechen
                        </Button>
                        <Button type={"submit"} color="primary" variant={"contained"}>
                            Speichern
                        </Button>
                    </DialogActions>
                </DialogContent>
            </form>
            {dialogA}
            {bpDialog}
            {tDialog}
            {iDialog}
        </Dialog>
        <Snackbar open={snackbarIsOpen} autoHideDuration={3000} onClose={()=>setSnackbarIsOpen(false)}>
            <Alert severity="error" onClose={()=>setSnackbarIsOpen(false)}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </>);

    return {
        dialog: dialog,
        interactions: {
            open:open,
            load:load,
            loadAndOpen:loadAndOpen,
            openWithGroup:openWithGroup
        }
    };
}