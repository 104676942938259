import {useState} from "react";
import {
    Button,
    Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import {getTokenResponse} from "../../utils/tokenResponse";
import {myApi} from "../../authConfig";
import {Cancel, Delete, Edit, Save, Visibility} from "@mui/icons-material";
import {useBulletPointGroupDialog} from "./BulletPointGroupDialog";

export type BulletPointGroup = {
    bullet_point_group_id:string,
    active:boolean,
    name:string,
    product_id:string
}

export const useBulletPointGroupsDialog = () => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [groups,setGroups]=useState<BulletPointGroup[]>([]);
    const [editId,setEditId] = useState("");
    const [nVName,setNVName] = useState("")
    const [nVActive,setNVActive] = useState(false)

    const [pId, setPId] = useState("");

    const {dialog:dialogZ, interactions} = useBulletPointGroupDialog();

    const onCloseDialog = () => {
        setDialogIsOpen(false);
        setGroups([])
    }

    const load = (pId:string) => {
        setDialogIsOpen(true)
        setPId(pId)
        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
            .then(async (headers)=>{

                const options = {
                    method: "GET",
                    headers: headers
                };

                const data: BulletPointGroup[] = await fetch(myApi.url+"/products/byId/"+pId+"/bullet_groups",options)
                    .then(response => response.json())
                    .catch(error => console.log(error));

                setGroups(data)

            })
    }

    const open = () => {
        setDialogIsOpen(true)
    }

    const dialog = <>
        <Dialog open={dialogIsOpen} onClose={onCloseDialog} fullWidth maxWidth={"lg"}>
            <DialogTitle>Bulletpointgruppen</DialogTitle>
            <DialogContent>
                <div className={"space-y-4 mt-2"}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Aktionen</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                groups.sort((a,b)=>(a.name>b.name)?1:((b.name>a.name)?-1:0)).map((group)=>(
                                    <TableRow key={group.bullet_point_group_id}>
                                        <TableCell>
                                            {group.bullet_point_group_id}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                editId===group.bullet_point_group_id? <TextField variant={"outlined"} value={group.name} onChange={(e)=>setGroups(groups.filter((g)=>g.bullet_point_group_id!==group.bullet_point_group_id).concat({...group,name:e.target.value}))}/>:group.name
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox checked={group.active} disabled={!(editId===group.bullet_point_group_id)} onChange={(e)=>setGroups(groups.filter((g)=>g.bullet_point_group_id!==group.bullet_point_group_id).concat({...group,active:e.target.checked}))}/>
                                        </TableCell>
                                        <TableCell>
                                            <div className={"flex space-x-3 items-center"}>
                                                <Delete
                                                    className={"cursor-pointer"}
                                                    onClick={()=>{
                                                        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                            .then(async (headers)=>{

                                                                const options = {
                                                                    method: "DELETE",
                                                                    headers: headers
                                                                };

                                                                const data= await fetch(myApi.url+"/products/byId/"+group.product_id+"/bullet_group/"+group.bullet_point_group_id,options)
                                                                    .then(response => response.json())
                                                                    .catch(error => console.log(error));

                                                                if(data.message==="success")
                                                                {
                                                                    setGroups(groups.filter((g)=>g.bullet_point_group_id!==group.bullet_point_group_id))
                                                                }

                                                            })
                                                    }}
                                                />
                                                {
                                                    editId===group.bullet_point_group_id?<>
                                                        <Save
                                                            className={"cursor-pointer"}
                                                            onClick={()=>{
                                                                getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                                    .then(async (headers)=>{
                                                                        headers.append('Content-Type', 'application/json')
                                                                        const options = {
                                                                            method: "POST",
                                                                            headers: headers,
                                                                            body: JSON.stringify({
                                                                                name:group.name,
                                                                                active:group.active
                                                                            })
                                                                        };

                                                                        const data = await fetch(myApi.url+"/products/byId/"+group.product_id+"/bullet_group/"+group.bullet_point_group_id,options)
                                                                            .then(response => response.json())
                                                                            .catch(error => console.log(error));

                                                                        setEditId("")
                                                                        setGroups(data.data)

                                                                    })
                                                            }}
                                                        />
                                                        <Cancel
                                                            className={"cursor-pointer "}
                                                            onClick={()=>setEditId("")}
                                                        />
                                                    </>:<Edit
                                                        className={"cursor-pointer"}
                                                        onClick={()=>{
                                                            setEditId(group.bullet_point_group_id)
                                                        }}
                                                    />

                                                }
                                                <Visibility
                                                    className={"cursor-pointer"}
                                                    onClick={()=>interactions.load(group.product_id,group.bullet_point_group_id)}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><TextField  value={nVName} variant={"outlined"} label={"Neue Gruppe"} onChange={(e)=>setNVName(e.target.value)} /></TableCell>
                                <TableCell><Checkbox checked={nVActive} onChange={(e)=>setNVActive(e.target.checked)} /></TableCell>
                                <TableCell>
                                    <Save className={"cursor-pointer"}
                                        onClick={()=>{
                                            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                .then(async (headers)=>{
                                                    headers.append('Content-Type', 'application/json')

                                                    const options = {
                                                        method: "POST",
                                                        headers: headers,
                                                        body: JSON.stringify({
                                                            name:nVName,
                                                            active:nVActive
                                                        })
                                                    };

                                                    const data = await fetch(myApi.url+"/products/byId/"+pId+"/bullet_group/",options)
                                                        .then(response => response.json())
                                                        .catch(error => console.log(error));

                                                    setGroups(data.data)

                                                })

                                            setNVActive(false);
                                            setNVName("");
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={onCloseDialog}>
                    Schließen
                </Button>
            </DialogActions>
            {dialogZ}
        </Dialog>
    </>

    return {
        dialog:dialog,
        interactions: {
            load:load,
            open:open
        }
    }

}