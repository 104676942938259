import {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {getTokenResponse} from "../../utils/tokenResponse";
import {myApi} from "../../authConfig";
import mimelite from "mime/lite";
import {Delete} from "@mui/icons-material";

export const useNewMedia = (whenUploaded:(files:{media_id:string,original_file_name:string}[],ref:string)=>void,verify:(file:File)=>boolean = ()=>{return true}) => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [multiple,setMultiple] = useState(false)

    const [fileList,setFileList] = useState<File[]>([]);

    const [ref,setRef] = useState("")

    const onCloseDialog = () => {
        setDialogIsOpen(false);

    }

    const open = (multiple:boolean = false,pRef:string="") => {
        setRef(pRef);
        setDialogIsOpen(true)
        setMultiple(multiple)
    }

    const upload = () => {
        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
            .then(async (headers)=>{

                headers.append('Content-Type', 'application/json')

                let media : {media_id:string,original_file_name:string}[] = [];

                for(const file of fileList)
                {
                    let ok= verify(file)

                    if(ok)
                    {
                        let options:RequestInit = {
                            method: "POST",
                            headers: headers,
                            body: JSON.stringify({
                                original_file_name:file.name
                            })
                        };

                        const d : {data:{media_id:string,original_file_name:string}}= await fetch(myApi.url+"/media/",options)
                            .then(response => response.json())
                            .catch(error => console.log(error))

                        media.push(d.data)

                        options = {
                            method: "GET",
                            headers: headers
                        }

                        console.log(headers)

                        const d2 = await fetch(myApi.url+"/media/"+d.data.media_id+"/getUploadLink",options)
                            .then(response => response.json())
                            .catch(error => console.log(error))

                        let t = mimelite.getType(file.name);

                        if(!t)
                            t=""

                        const hNew = new Headers();

                        hNew.append("Content-Type",t)

                        options = {
                            method: "PUT",
                            headers: hNew,
                            body: await file.arrayBuffer()
                        }

                        await fetch(d2.link,options)
                            .then(response => response)
                            .catch(error => console.log(error))
                    }
                }
                whenUploaded(media.sort((a,b)=>a.original_file_name<b.original_file_name?-1:(a.original_file_name>b.original_file_name?1:0)),ref)

                setFileList([]);



            })
            .finally(()=>{
                onCloseDialog();
            })
    }
    
    const dialog = <>
        <Dialog open={dialogIsOpen} onClose={onCloseDialog}>
            <DialogTitle>Datei Upload</DialogTitle>
            <DialogContent>
                <div className={"w-full items-center justify-center flex"}>
                    <div className={"justify-center items-center flex-col flex"}>
                        <div className={"space-y-2 my-2"}>
                            {
                                fileList.map((file,index)=>(
                                    <div className={"flex"}>
                                        <div className={"flex-grow"}>{file.name}</div>
                                        <div><Delete className={"cursor-pointer"} onClick={()=>{
                                            setFileList(fileList.filter((f,i)=>index!==i))
                                        }}/></div>
                                    </div>
                                ))
                            }
                        </div>
                        <Button component={"label"} variant={"contained"} startIcon={<CloudUploadIcon/>}>
                            Auswählen
                            <input type={"file"} className={"!hidden"} multiple={multiple} onChange={(e)=>{
                                if(e.target.files)
                                {
                                    if(multiple||fileList.length===0) {
                                        let lFL = [...fileList];

                                        for(let i = 0;i<e.target.files.length;i++) {

                                            const f = e.target.files.item(i);
                                            if(f)
                                                lFL.push(f)

                                        }

                                        setFileList(lFL)
                                    }
                                    else {
                                        alert("Es darf nur eine Datei ausgewählt werden.")
                                    }

                                }
                            }}/>
                        </Button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} color={"error"} onClick={onCloseDialog}>
                    Abbrechen
                </Button>
                <Button variant={"contained"} color={"primary"} onClick={upload}>
                    Hochladen
                </Button>
            </DialogActions>
        </Dialog>
    </>

    return {
        dialog:dialog,
        interactions: {
            open:open
        }
    }

}