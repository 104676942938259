import {useState} from "react";
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow, TextField, Tooltip
} from "@mui/material";
import {myApi} from "../../authConfig";
import {getTokenResponse} from "../../utils/tokenResponse";
import {Cancel, Computer, Delete, Edit, Notes, Save} from "@mui/icons-material";
import {TypeTextDialog} from "./TypeTextDialog";


type ProductText = {
    product_id:string,
    product_text_id:string,
    active:boolean,
    internalComment: string,
    text:string
}
export const useProductTextDialog = () => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [pId, setPId] = useState("")
    const [texts,setTexts] = useState<ProductText[]>([]);
    const [editId,setEditId] = useState("");
    const [eNVA,setENVA] = useState<boolean>(false);
    const [eNVC,setENVC] = useState<string>("");
    const [nVA,setNVA] = useState<boolean>(false);
    const [nVC,setNVC] = useState<string>("");
    const onCloseDialog = () => {
        setDialogIsOpen(false);
    }

    const load = (id:string) => {

        setPId(id)

        setDialogIsOpen(true)
        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
            .then(async (headers)=>{

                const options = {
                    method: "GET",
                    headers: headers
                };

                const data: ProductText[] = await fetch(myApi.url+"/products/byId/"+id+"/texts",options)
                    .then(response => response.json())
                    .catch(error => console.log(error));

                setTexts(data)

            })
    }

    const dialog = <>
        <Dialog open={dialogIsOpen} onClose={onCloseDialog} fullWidth maxWidth={"lg"}>
            <DialogTitle>Texte zum Produkt</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Interner Kommentar</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            texts && texts.map((text)=>(
                                <TableRow>
                                    <TableCell>{text.product_text_id}</TableCell>
                                    <TableCell>{editId===text.product_text_id?<TextField variant={"outlined"} value={eNVC} onChange={(e)=>setENVC(e.target.value)}/>:text.internalComment }</TableCell>
                                    <TableCell><Checkbox checked={editId===text.product_text_id?eNVA:text.active} disabled={!(editId===text.product_text_id)} onChange={(e)=>setENVA(e.target.checked)}/> </TableCell>
                                    <TableCell>
                                        <div className={"flex space-x-3"}>
                                            <Delete
                                                className={"cursor-pointer"}
                                                onClick={()=>{
                                                    getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                        .then(async (headers)=>{
                                                            headers.append('Content-Type', 'application/json')

                                                            const options = {
                                                                method: "DELETE",
                                                                headers: headers
                                                            };

                                                            const data = await fetch(myApi.url+"/products/byId/"+pId+"/text/"+text.product_text_id,options)
                                                                .then(response => response.json())
                                                                .catch(error => console.log(error));

                                                            if(data.message==="success")
                                                            {
                                                                setTexts(texts.filter((t)=>t.product_text_id!==text.product_text_id))
                                                            }

                                                        })
                                                }}/>
                                            {
                                                editId===text.product_text_id? <>
                                                    <Save
                                                        className={"cursor-pointer"}
                                                        onClick={()=>{
                                                            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                                .then(async (headers)=>{
                                                                    headers.append('Content-Type', 'application/json')

                                                                    const options = {
                                                                        method: "POST",
                                                                        headers: headers,
                                                                        body: JSON.stringify({
                                                                            internalComment:eNVC,
                                                                            active:eNVA
                                                                        })
                                                                    };

                                                                    const data = await fetch(myApi.url+"/products/byId/"+pId+"/text/"+text.product_text_id,options)
                                                                        .then(response => response.json())
                                                                        .catch(error => console.log(error));

                                                                    if(data.message==="success")
                                                                    {

                                                                        setEditId("");
                                                                        if(!eNVA)
                                                                        {
                                                                            setTexts(texts.filter((t)=>t.product_text_id!==text.product_text_id).concat({
                                                                                ...text,
                                                                                active:eNVA,
                                                                                internalComment:eNVC
                                                                            }))

                                                                        }
                                                                        else {
                                                                            setTexts(texts.filter((t)=>t.product_text_id!==text.product_text_id&&!t.active).concat(texts.filter((t)=>t.product_text_id!==text.product_text_id&&t.active).map((t)=>{
                                                                                return {
                                                                                    ...t,
                                                                                    active:false
                                                                                }
                                                                            })).concat({
                                                                                ...text,
                                                                                active:eNVA,
                                                                                internalComment:eNVC
                                                                            }))

                                                                        }
                                                                    }

                                                                })
                                                        }}

                                                    />
                                                    <Cancel
                                                        className={"cursor-pointer"}
                                                        onClick={()=>{
                                                            setEditId("")

                                                        }}
                                                    />
                                                </>: <>
                                                    <Edit
                                                        className={"cursor-pointer"}
                                                        onClick={()=>{
                                                            setEditId(text.product_text_id)
                                                            setENVA(text.active)
                                                            setENVC(text.internalComment)
                                                            console.log(text.text)
                                                        }}
                                                    />
                                                    <TypeTextDialog text={text.text} save={(html)=>{
                                                        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                            .then(async (headers)=>{
                                                                headers.append('Content-Type', 'application/json')

                                                                const options = {
                                                                    method: "POST",
                                                                    headers: headers,
                                                                    body: JSON.stringify({
                                                                        text:html
                                                                    })
                                                                };

                                                                const data = await fetch(myApi.url+"/products/byId/"+pId+"/text/"+text.product_text_id+"/setText",options)
                                                                    .then(response => response.json())
                                                                    .catch(error => console.log(error));

                                                                if(data.message==="success")
                                                                {

                                                                    setTexts(texts.filter((t)=>t.product_text_id!==text.product_text_id).concat(texts.filter((t)=>t.product_text_id===text.product_text_id).map((t)=>{
                                                                        return {
                                                                            ...t,
                                                                            text:html
                                                                        }
                                                                    })))
                                                                }

                                                            })
                                                    }}>
                                                        <Notes className={"cursor-pointer"}/>
                                                    </TypeTextDialog>
                                                    <Tooltip title={"Text per KI generieren"}>
                                                        <Computer
                                                            className={"cursor-pointer"}
                                                            onClick={()=>{
                                                                getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                                                    .then(async (headers)=>{

                                                                        const options = {
                                                                            method: "GET",
                                                                            headers: headers
                                                                        };

                                                                        await fetch(myApi.url+"/products/byId/"+pId+"/text/"+text.product_text_id+"/genKi",options)
                                                                            .then(response => response.json())
                                                                            .catch(error => console.log(error));


                                                                    })
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                        </div>
                                    </TableCell>
                                </TableRow>

                            ))
                        }
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell><TextField variant={"outlined"} value={nVC} onChange={(e)=>setNVC(e.target.value)}/></TableCell>
                            <TableCell><Checkbox checked={nVA}  onChange={(e)=>setNVA(e.target.checked)}/> </TableCell>
                            <TableCell>
                                <Save
                                    className={"cursor-pointer"}
                                    onClick={()=>{
                                        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                                            .then(async (headers)=>{
                                                headers.append('Content-Type', 'application/json')

                                                const options = {
                                                    method: "POST",
                                                    headers: headers,
                                                    body: JSON.stringify({
                                                        internalComment:nVC,
                                                        active:nVA
                                                    })
                                                };

                                                const data = await fetch(myApi.url+"/products/byId/"+pId+"/text",options)
                                                    .then(response => response.json())
                                                    .catch(error => console.log(error));

                                                if(data.message==="success")
                                                {

                                                    setNVA(false);
                                                    setNVC("")
                                                    setTexts(texts.filter((t)=>!t.active).concat(texts.filter((t)=>t.active).map((t)=>{
                                                        return {
                                                            ...t,
                                                            active: false
                                                        }
                                                    })).concat(data.data))

                                                }

                                            })
                                    }}

                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={onCloseDialog}>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    </>

    return {
        dialog:dialog,
        interactions: {
            load:load
        }
    }

}