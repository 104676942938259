import { UnauthenticatedTemplate, useMsal} from "@azure/msal-react";
import {Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export function Root() {

    const {instance, accounts} = useMsal();
    const navigate = useNavigate();

    useEffect(()=>{
        if(accounts.length>0)
        {
            console.log(accounts.length)
            navigate("/intern/dashboard");
        }
    });

    return (
        <div className={"h-screen items-center flex flex-col justify-center"}>
            <UnauthenticatedTemplate>
                <Typography variant={"h6"} >Sie sind nicht angemeldet</Typography>
                <Typography >Bitte melden Sie sich an, indem Sie auf den Knopf "Anmelden" drücken.</Typography>
                <Button onClick={()=>instance.loginRedirect()}>Anmelden</Button>
            </UnauthenticatedTemplate>

        </div>
    )
}