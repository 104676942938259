import {MsalAuthenticationTemplate, useMsal} from "@azure/msal-react";
import {
    AccountInfo,
    InteractionRequiredAuthError,
    InteractionStatus,
    InteractionType,
    RedirectRequest
} from "@azure/msal-browser";
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {callMsGraph} from "../utils/MsGraphApiCall";
import {GraphData} from "../types/GraphData";
import {Outlet, useNavigate} from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import {MenuItemDef} from "../components/MenuDefinition";


const pages:MenuItemDef[] = [
    {name:"Produktkategorien",internalLink:"/intern/product_categories"},
    {name:"Artikel",internalLink:"/intern/products"},
    {name:"Attribute",internalLink:"/intern/attributes"},
    {name:"AI",internalLink:"/intern/ai"}

];
export function AuthenticatedRoutes() {

    const { instance, inProgress } = useMsal();
    const [graphData, setGraphData] = useState<null|GraphData>(null);
    const [imageUrl, setImageUrl] = useState<string>("/static/images/avatar/2.jpg");

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const request :RedirectRequest = {
        scopes:["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read",
            "api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Write",
            "api://58eba143-b1bd-474a-86dc-966e7236cf7d/AdminSettings",
        "api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Read"],

    }

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (!graphData && inProgress === InteractionStatus.None) {
            callMsGraph().then((resp) => {
                setGraphData(resp.response)
                setImageUrl(resp.pic)
            }).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenPopup({
                        scopes:["User.Read"],
                        account: instance.getActiveAccount() as AccountInfo
                    });
                }
            });

        }
    }, [inProgress,graphData,instance]);

    return <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={request}

    >
        <AppBar position={"static"}>
            <Container maxWidth={"xl"}>
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        M PM
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={()=>{handleCloseNavMenu();navigate(page.internalLink)}}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        M PM
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                onClick={()=>{handleCloseNavMenu(); navigate(page.internalLink)}}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Öffne Einstellungen">
                            <IconButton  sx={{ p: 0 }}>
                                <Avatar alt="Tim Riep" src={imageUrl} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            //anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={false}
                            //onClose={handleCloseUserMenu}
                        >
                            asd
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
        <Outlet/>
    </MsalAuthenticationTemplate>

}