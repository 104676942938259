import {ReactElement, useEffect, useState} from "react";
import {DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp} from "@mui/x-data-grid";
import {getTokenResponse} from "../../utils/tokenResponse";
import {myApi} from "../../authConfig";

export type SimpleAttribute =  {
    attribute_id:string,
    name:string
}

export const useAttributeGrid = ({onSelection}:{onSelection?:(type:"NONE"|"A",id?:string)=>void}) => {

    const [rows, setRows] = useState<GridRowsProp>([]);


    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Eindeutige Identifikationsnummer',
            minWidth:300
        },
        {
            field: 'name',
            headerName: 'Attributname'
        }
    ];

    useEffect(() => {
        renewData();
    }, []);

    const renewData = () => {
        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Read"])
            .then(async (headers)=>{

                const options = {
                    method: 'GET',
                    headers: headers
                }

                const lRes = await fetch(myApi.url+"/attributes/",options)
                    .then(response =>response.json())
                    .catch(error=>console.log(error));

                setRows(lRes.map((lAttribute:SimpleAttribute)=>{
                    return {
                        id:lAttribute.attribute_id,
                        name:lAttribute.name
                    }
                }))

            })
    }

    const onSelectionChange= (a:GridRowSelectionModel) => {


        if(onSelection)
        {
            if(a.length!==0)
            {
                if(typeof a[0]==='string') {
                    onSelection("A", a[0])
                }
            }
            else
            {
                onSelection("NONE")
            }
        }

    }

    const grid : ReactElement = <>
        <DataGrid columns={columns} rows={rows} onRowSelectionModelChange={onSelectionChange}/>
    </>;

    return {
        grid:grid,
        renewData:renewData
    };
}