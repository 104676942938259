import {useCategoryTreeWithEditOptions} from "../hooks/useCategoryTreeWithEditOptions";
import {Button} from "@mui/material";
import React, {useEffect} from "react";
import {SelectCategory} from "./dialogs/SelectCategory";
import {useProductGroupEditor} from "./dialogs/ProductGroupEditor";
import {getTokenResponse} from "../utils/tokenResponse";

export default function Test  ()  {

    const {categoryBrowser,categoryTreeModal,interactions} = useCategoryTreeWithEditOptions();

    const {dialog, interactions:int} = useProductGroupEditor();

    useEffect(() => {
        getTokenResponse(["openid"]).then(headers=>console.log(headers))
    }, []);

    return <>
        {categoryBrowser}
        {categoryTreeModal}
        <Button variant="contained" color="primary" onClick={interactions.openCategoryCreationDialog}>
            Kategorie hinzufügen
        </Button>
        <Button variant="contained" color="primary" onClick={interactions.deleteSelectedCategory}>
            Delete
        </Button>
        <Button variant={!interactions.moveSelectedCategory.moveIsActive ? 'contained' : 'outlined'} color="primary"
                onClick={interactions.moveSelectedCategory.toggleMove}>
            Verschieben
        </Button>
        <Button variant="contained" color="primary" onClick={interactions.openEditDialog}>
            Edit
        </Button>
        <SelectCategory title={"Kategorie Hinzufügen"}>
            Test
        </SelectCategory>
        <Button onClick={()=>int.loadAndOpen("104b64c5-88f6-4538-944e-150b5061eae1")}>Test-Produkt</Button>
        {dialog}

    </>
}