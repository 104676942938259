import {AccountInfo, AuthenticationResult, InteractionRequiredAuthError} from "@azure/msal-browser";
import {msalInstance} from "../index";

export const getTokenResponse = async (scopes:string[]):Promise<Headers> => {

    const getHeader = (authResult: AuthenticationResult):Headers => {
        if(!authResult)
            throw new Error("no Resp.")


        const headers = new Headers();
        const bearer = `Bearer ${authResult.accessToken}`;

        headers.append("Authorization", bearer);

        return headers;
    }

    return new Promise<Headers>(async (resolve)=>{
        const account = msalInstance.getActiveAccount();
        if(!account)
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");

        try {
            const response = await msalInstance.acquireTokenSilent({
                scopes :scopes,
                account: account
            })
            resolve(getHeader(response))
        }
        catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
                try {
                    const response = await msalInstance.acquireTokenPopup({
                        scopes: scopes,
                        account: msalInstance.getActiveAccount() as AccountInfo
                    })
                    resolve(getHeader(response))
                } catch (e) {
                    throw Error("Response not possible");
                }
            }
        }
    })
}