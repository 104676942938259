import {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

export const useShowImage = () => {
    const [dialogIsOpen,setDialogIsOpen] = useState(false);
    const [imgSrc,setImgSrc] = useState("")

    const onCloseDialog = () => {
        setDialogIsOpen(false)
    }

    const open =(src:string) => {
        setDialogIsOpen(true)
        setImgSrc(src)
    }


    const dialog = <>
        <Dialog open={dialogIsOpen} onClose={onCloseDialog}>
            <DialogTitle>
                Produktbild
            </DialogTitle>
            <DialogContent className={"my-2"}>
                <img width={"auto"} src={imgSrc} alt={"NOT PROVIDED"}/>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={onCloseDialog}>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    </>

    return {
        dialog:dialog,
        interactions: {
            open:open
        }
    }

}