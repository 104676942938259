import { msalInstance } from "../index";


type CallMsGraphReturn = {
    response: any,
    pic:string
}
export async function callMsGraph():Promise<CallMsGraphReturn> {

        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const respons = await msalInstance.acquireTokenSilent({
            scopes :["User.Read"],
            account: account
        });

        const headers = new Headers();
        const bearer = `Bearer ${respons.accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers
        };

        const response = await fetch("https://graph.microsoft.com/v1.0/me", options)
            .then(response => response.json())
            .catch(error => console.log(error));

        const dat2 = await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", options)
            .then(response => response.blob())
            .catch(error => console.log(error));
        const url = window.URL ||window.webkitURL;

        let pic="asdhasdhasd.jpg"

        if(dat2)
            pic = url.createObjectURL(dat2)

        return Promise.resolve({response:response,pic:pic})
}
