import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, TextField
} from "@mui/material";
import React, {ReactElement, useState} from "react";
import {ConnectPPGAttribute} from "../../types/connect_product";
import {getTokenResponse} from "../../utils/tokenResponse";
import {myApi} from "../../authConfig";
export const ViewProductAttributes = ({children,type,id}:{children?:ReactElement,type:"product"|"product_group",id:string}) => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);



    const [attributes, setAttributes] = useState<ConnectPPGAttribute[]>([]);

    const openDialog = () => {
        setDialogIsOpen(true);
        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
            .then(async (headers)=>{
                const options = {
                    method: "GET",
                    headers:headers
                }

                const data2 = await fetch(myApi.url+"/connect/"+type+"/"+id, options)
                    .then(response => response.json())
                    .catch(error => console.log(error));

                setAttributes(data2.attributes)
            })
    }

    const onCloseDialog = () => {
        setDialogIsOpen(false)
    }

    return(
        <>
            <Button variant={"contained"} color={"primary"} onClick={openDialog}>
                {children}
            </Button>
            <Dialog open={dialogIsOpen} onClose={onCloseDialog} fullWidth maxWidth={"lg"}>
                <DialogTitle>Attributstruktur</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Hier werden die gespeicherten Attribute mit Berücksichtigung aller Vererbungsbesonderheiten berücksichtigt.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        {
                            attributes.map((a)=>(
                                <Grid item xs={6}>
                                    {
                                        a.multipleValues ? <div className={"flex w-full items-center space-x-4"}>
                                            <Autocomplete
                                                className={"w-full"}
                                                disabled
                                                readOnly
                                                multiple
                                                renderInput={(params)=>(<TextField {...params} label={a.name} />)}
                                                defaultValue={a.assignedValues.map((val)=>val.type==="STR"?val.value:val.acValue?.name)}
                                                options={a.assignedValues.map((val)=>val.type==="STR"?val.value:val.acValue?.name)}/>

                                        </div>:<div className={"flex items-center space-x-4"}>
                                            <TextField className={"w-full"} variant={"outlined"} label={a.name} disabled defaultValue={a.assignedValues[0]?(a.assignedValues[0].type==="STR"?a.assignedValues[0].value:a.assignedValues[0].acValue?.name):("") }/>

                                        </div>
                                    }
                                </Grid>
                            ))
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseDialog} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}