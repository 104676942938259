import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem, ListItemButton
} from "@mui/material";
import React, {ReactElement, useState} from "react";

export const SelectInheritance = ({children,onSelection}:{children?:ReactElement|string,onSelection?:(option:"OVERWRITE"|"ADDITIONAL"|"INHERIT")=>void}) : ReactElement => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const openDialog = () => {
        setDialogIsOpen(true);
    }

    const onCloseDialog = () => {
        setDialogIsOpen(false)
    }

    const onCommit = (option:"OVERWRITE"|"ADDITIONAL"|"INHERIT") => {
        onCloseDialog()

        if(onSelection)
            onSelection(option)
    }


    return(
        <>
            <div onClick={openDialog} className={"cursor-pointer w-full"}>
                {children}
            </div>
            <Dialog open={dialogIsOpen} onClose={onCloseDialog}>
                <DialogTitle>Vererbungsoption auswählen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte wähle eine Art der Vererbung aus.
                    </DialogContentText>
                    <List>
                        <ListItem disableGutters key={"OVERWRITE"}>
                            <ListItemButton onClick={()=>onCommit("OVERWRITE")}>
                                Überschreiben
                            </ListItemButton>
                        </ListItem>
                        <ListItem disableGutters key={"INHERIT"}>
                            <ListItemButton onClick={()=>onCommit("INHERIT")}>
                                Übernehmen
                            </ListItemButton>
                        </ListItem>
                        <ListItem disableGutters key={"ADDITIONAL"}>
                            <ListItemButton onClick={()=>onCommit("ADDITIONAL")}>
                                Erweitern
                            </ListItemButton>
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseDialog} color="error">
                        Abbrechen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}