import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./authConfig";
import {BrowserRouter} from "react-router-dom";
import {render} from "react-dom";


export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(()=>{
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }
    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
    });


    render(
        <React.StrictMode>
            <BrowserRouter><App pca={msalInstance} /></BrowserRouter>

        </React.StrictMode>,
        document.getElementById('root')
    );
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
