import React, {ReactElement, useEffect, useState} from "react";
import {TreeItem, TreeItemContentProps, TreeItemProps, TreeView, useTreeItem} from "@mui/x-tree-view";
import clsx from "clsx";
import {Typography} from "@mui/material";
import {Category} from "../types/Category";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {getTokenResponse} from "../utils/tokenResponse";
import {myApi} from "../authConfig";

export type CategoryTreeInteraction = {
    baseCategory: Category,
    setBaseCategory: React.Dispatch<React.SetStateAction<Category>>,
    selectedCategoryId: string|null,
    setSelectedCategoryId: React.Dispatch<React.SetStateAction<string|null>>,
    getCategoryById: (lCategory_id:string|null, lBase_category?:Category)=>Category|null,
    getCategoryName: (lCategory_id:string|null)=>string,
    getAllChildrenIds: (lCategory:Category)=>string[],
    initialize:()=>void

}

type CategoryTreeProps = {
    onSelection?:(category_id:string|null)=>void
}

type CategoryTreeReturnType = {
    categoryBrowser: ReactElement,
    categoryBrowserInteractions: CategoryTreeInteraction
}

export function useCategoryTree({onSelection}:CategoryTreeProps,ini:boolean=true): CategoryTreeReturnType {

    const [baseCategory,setBaseCategory] = useState<Category>({
        category_id:null,
        parent_category_id:null,
        name:"Basiskategorie",
        children: []
    });

    const [selectedCategoryId, setSelectedCategoryId] = useState<null|string>(null);

    const [statIni, setStatIni] = useState<boolean>(ini);

    const onNodeSelect = (e:React.SyntheticEvent,lCategory_id:string|null) => {

        if(lCategory_id==="")
            lCategory_id=null;

        setSelectedCategoryId(lCategory_id);

        if(onSelection)
            onSelection(lCategory_id);
    }

    const categoryBrowser:ReactElement= (

        <TreeView
        aria-label="icon expansion"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        onNodeSelect={onNodeSelect}
        >
            <ShowCat category={baseCategory}/>
        </TreeView>
    );

    const initialize = () => {
            setStatIni(true)
    }

    useEffect(() => {
        if(statIni)
            intInitialize()
    }, [statIni]);

    const getAllChildrenIds = (lCategory:Category) : string[] => {

        let lChildrenIds: string[] = [];
        const getAllChildrenIds_extractIds = (lCc: Category) => {

            if(lCc.category_id)
                lChildrenIds.push(lCc.category_id)

            if(lCc.children)
            {
                lCc.children.forEach((lChild)=>{
                    getAllChildrenIds_extractIds(lChild)
                })
            }

        }

        getAllChildrenIds_extractIds(lCategory)

        return lChildrenIds;

    }

    const intInitialize = () => {
        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
            .then(async (headers)=>{

                const options = {
                    method: "GET",
                    headers: headers
                };

                const data: Category[] = await fetch(myApi.url+"/categories",options)
                    .then(response => response.json())
                    .catch(error => console.log(error));

                let basCat : Category = {
                    name: "Basiskategorie",
                    category_id:null,
                    parent_category_id:null,
                    children : []
                }

                const getWithChildren = (cat:Category):Category => {

                    let c = data.filter(d => d.parent_category_id === cat.category_id)

                    c = c.map((cc)=> {
                        return getWithChildren(cc)
                    })

                    return {...cat, children: c}
                }

                basCat = getWithChildren(basCat)

                setBaseCategory(basCat)

            })
    }

    const getCategoryById = (lCategory_id: string | null, lBaseCategory: Category = baseCategory): Category | null => {

        const getCategoryByIdHelper = (lChildren:Category[],lHCategory_id:string|null):Category|null =>{

            let r:null|Category=null;

            for(const lChild of lChildren)
            {
                if (lChild.category_id===lHCategory_id)
                {
                    return lChild;
                }
                else if(lChild.children.length>0)
                {
                    r = getCategoryByIdHelper(lChild.children,lHCategory_id)

                    if(r)
                        return r;
                }
            }

            return r;
        }

        if (lBaseCategory.category_id === lCategory_id)
            return lBaseCategory;

        let r:null|Category = null;

        if (lBaseCategory.children.length > 0) {
            r = getCategoryByIdHelper(lBaseCategory.children, lCategory_id)
        }

        return r;
    }

    const getCategoryName = (category_id:string|null) => {
        const lData = getCategoryById(category_id);

        if(lData)
            return lData.name;
        else
            return "Error"
    }

    return {
        categoryBrowser:categoryBrowser,
        categoryBrowserInteractions:{
            baseCategory:baseCategory,
            setBaseCategory:setBaseCategory,
            selectedCategoryId:selectedCategoryId,
            setSelectedCategoryId:setSelectedCategoryId,
            getCategoryById:getCategoryById,
            getCategoryName:getCategoryName,
            getAllChildrenIds: getAllChildrenIds,
            initialize: initialize
        }
    };

}

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props:TreeItemProps,ref:React.Ref<HTMLLIElement>){
    return <TreeItem  {...props} ContentComponent={CustomContent} ref={ref} />;
})

const CustomContent = React.forwardRef(function CustomContent(
    props: TreeItemContentProps,
    ref,
) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
    } = props;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        preventSelection(event);
    };

    const handleExpansionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        handleSelection(event);
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref as React.Ref<HTMLDivElement>}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography
                onClick={handleSelectionClick}
                component="div"
                className={classes.label}
            >
                {label}
            </Typography>
        </div>
    );
});


type ShowCatProps = {
    category:Category
}
const ShowCat = ({category}:ShowCatProps) => {

    let id = category.category_id;

    if(id==null)
        id=""

    return (<>
        <div key={id}>
            <CustomTreeItem nodeId={id}  label={category.name} key={id}>
                {
                    category.children.map((cat)=> (<ShowCat category={cat}/>))
                }
            </CustomTreeItem>
        </div>
    </>)
}