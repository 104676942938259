import React, {ReactElement, useState} from "react";
import {Attribute} from "../../pages/Attributes";
import {getTokenResponse} from "../../utils/tokenResponse";
import {myApi} from "../../authConfig";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemButton
} from "@mui/material";

export const SelectAttribute = ({children,onSelection}:{children?:ReactElement|string,onSelection?:(option:Attribute)=>void}) => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const [attributes, setAttributes] = useState<Attribute[]>([]);

    const openDialog = () => {
        setDialogIsOpen(true)
        if(attributes.length===0)
        {
            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Read"])
                .then(async (headers)=>{
                    const options = {
                        method: "GET",
                        headers:headers
                    }

                    const data2 = await fetch(myApi.url+"/attributes", options)
                        .then(response => response.json())
                        .catch(error => console.log(error));


                    setAttributes(data2)



                })
        }
    }

    const onCloseDialog = () => {
        setDialogIsOpen(false)
    }

    const onCommit = (option:Attribute) => {
        onCloseDialog()

        if(onSelection)
            onSelection(option)
    }

    return(
        <>
            <div onClick={openDialog} className={"cursor-pointer w-full"}>
                {children}
            </div>
            <Dialog open={dialogIsOpen} onClose={onCloseDialog}>
                <DialogTitle>Attribut Auswählen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte wähle ein Attribut aus.
                    </DialogContentText>
                    <List>
                        {
                            attributes.map((a)=>(
                                    <ListItem disableGutters key={a.attribute_id}>
                                        <ListItemButton onClick={()=>onCommit(a)}>
                                            {a.name}
                                        </ListItemButton>
                                    </ListItem>
                                )
                            )
                        }

                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseDialog} color="error">
                        Abbrechen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}