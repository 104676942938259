import {CategoryTreeInteraction, useCategoryTree} from "./useCategoryTree";
import React, {ReactElement, useState} from "react";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Modal,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {getTokenResponse} from "../utils/tokenResponse";
import {myApi} from "../authConfig";
import {Category} from "../types/Category";

type UseCategoryTreeWithEditOptionsReturnType = {
    categoryBrowser: ReactElement,
    categoryTreeModal: ReactElement,
    interactions: UseCategoryTreeWithEditOptionsInteractions
}

type UseCategoryTreeWithEditOptionsInteractions = {
    openCategoryCreationDialog: () => void,
    deleteSelectedCategory: () => void,
    moveSelectedCategory: CatTreeDeleteFunction,
    openEditDialog: () => void,
    browserInteractions: CategoryTreeInteraction
}

type CatTreeDeleteFunction = {
    moveIsActive: boolean,
    toggleMove: () => void
}

export function useCategoryTreeWithEditOptions(): UseCategoryTreeWithEditOptionsReturnType {

    const onSelection = (category_id: string | null) => {

        if (moveIsActive)
            moveCategory(category_id);

    }

    const {categoryBrowser, categoryBrowserInteractions} = useCategoryTree({onSelection: onSelection});

    const [modelIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [snackbarIsOpen, setSnackbarIsOpen] = useState<boolean>(false);

    const [newCategoryName, setNewCategoryName] = useState<string>("");

    const [snackbarMessage, setSnackbarMessage] = useState<string>("");

    const [moveIsActive, setMoveIsActive] = useState<boolean>(false);
    const [moveId, setMoveId] = useState<string | null>("")

    const [editDialogIsOpen, setEditDialogIsOpen] = useState<boolean>(false);

    const handleCloseDialog = () => {
        setNewCategoryName("");
        setEditDialogIsOpen(false)
    }

    const handleSaveDialog = () => {

        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Write"])
            .then(async (headers) => {
                headers.append('Content-Type', 'application/json')

                const options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(
                        {
                            category_id: categoryBrowserInteractions.selectedCategoryId,
                            name: newCategoryName,
                            parent_category_id: categoryBrowserInteractions.getCategoryById(categoryBrowserInteractions.selectedCategoryId)?.parent_category_id
                        }
                    )
                };

                const data = await fetch(myApi.url + "/categories", options)
                    .then(response => response.json())
                    .catch(error => console.log(error));

                if (data.message === "success") {
                    const lParentId = categoryBrowserInteractions.getCategoryById(categoryBrowserInteractions.selectedCategoryId)?.parent_category_id;

                    if (lParentId === undefined)
                        return;

                    const lParent = categoryBrowserInteractions.getCategoryById(lParentId);

                    if (!lParent)
                        throw new Error("pN not found");

                    const lNewChildren = lParent.children.map((lChild) => {

                        if (lChild.category_id === categoryBrowserInteractions.selectedCategoryId)
                            return {...lChild, name: newCategoryName}

                        return lChild;
                    })

                    const nC = updateChildren(categoryBrowserInteractions.baseCategory, lParentId, lNewChildren);

                    categoryBrowserInteractions.setBaseCategory(nC)
                }

            })

        handleCloseDialog();
    }

    const openEditDialog = () => {

        if (!categoryBrowserInteractions.selectedCategoryId) {
            setSnackbarMessage('Du hast keine Kategorie ausgewählt oder versuchst die Basiskategorie zu ändern.');
            setSnackbarIsOpen(true);
            return;
        }

        setNewCategoryName(categoryBrowserInteractions.getCategoryName(categoryBrowserInteractions.selectedCategoryId))
        setEditDialogIsOpen(true);

    }

    const moveCategory = (lCategory_id: string | null) => {

        if (moveId === lCategory_id) {
            setSnackbarMessage('Du kannst die Kategorie nicht in sich selbst verschieben.');
            setSnackbarIsOpen(true);
            return;
        }
        const lMovKat = categoryBrowserInteractions.getCategoryById(moveId);

        if (!lMovKat)
            return;

        if (lCategory_id !== null && categoryBrowserInteractions.getAllChildrenIds(lMovKat).includes(lCategory_id)) {
            setSnackbarMessage('Du kannst die Kategorie nicht in ein Kind von ihr verschieben.');
            setSnackbarIsOpen(true);
            return;
        }

        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Write"])
            .then(async (headers) => {

                headers.append('Content-Type', 'application/json')

                const options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({category_id: moveId, parent_category_id: lCategory_id, name: lMovKat.name})
                };

                const data = await fetch(myApi.url + "/categories", options)
                    .then(response => response.json())
                    .catch(error => console.log(error));

                if (data.message === "success") {

                    let lParent = categoryBrowserInteractions.getCategoryById(lMovKat.parent_category_id);

                    if (lParent) {
                        let lPChildren = lParent.children;

                        lPChildren = lPChildren.filter((lPChild) => lPChild.category_id !== lMovKat?.category_id)

                        const nC = updateChildren(categoryBrowserInteractions.baseCategory, lMovKat.parent_category_id, lPChildren)

                        const lNCatP = categoryBrowserInteractions.getCategoryById(lCategory_id, nC)

                        if (lNCatP) {
                            let lNCatPChildren = lNCatP.children;

                            lMovKat.parent_category_id = lCategory_id;


                            const lNCatPChildrenNew = lNCatPChildren.concat(lMovKat)

                            const nC2 = updateChildren(nC, lCategory_id, lNCatPChildrenNew)

                            categoryBrowserInteractions.setBaseCategory(nC2)

                        }

                    }

                    setMoveIsActive(false)

                }

            })

    }

    const updateChildren = (lCategory: Category, lIdToUpdate: string | null, newChildren: Category[]): Category => {

        const updateChildrenHelper = (lCategories: Category[]): Category[] => {

            return lCategories.map((lCat) => {
                if (lCat.category_id === lIdToUpdate) {

                    return {...lCat, children: newChildren};

                } else if (lCat.children) {

                    return {...lCat, children: updateChildrenHelper(lCat.children)};

                }
                return lCat;
            });

        }

        if (lCategory.category_id === lIdToUpdate)
            return {...lCategory, children: newChildren}
        else {
            return {...lCategory, children: updateChildrenHelper(lCategory.children)}


        }

    }

    const handleCloseModal = () => {
        setModalIsOpen(false)
    }

    const openCategoryCreationDialog = () => {
        setModalIsOpen(true)
    }

    const handleCategoryCreation = () => {

        if (newCategoryName.trim() === '') {
            setSnackbarMessage('Der Name der Kategorie darf nicht leer sein.');
            setSnackbarIsOpen(true);
        } else {


            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Write"])
                .then(async (headers) => {
                    headers.append('Content-Type', 'application/json')

                    const options = {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify({
                            name: newCategoryName,
                            parent_category_id: categoryBrowserInteractions.selectedCategoryId
                        })
                    };

                    const data = await fetch(myApi.url + "/categories", options)
                        .then(response => response.json())
                        .catch(error => console.log(error));

                    if (data.message === "success") {
                        const pN = categoryBrowserInteractions.getCategoryById(categoryBrowserInteractions.selectedCategoryId);

                        if (!pN)
                            throw new Error("pN not found");
                        const nId: string = data.id;

                        let pNC = pN.children.concat({
                            name: newCategoryName,
                            parent_category_id: categoryBrowserInteractions.selectedCategoryId,
                            category_id: nId,
                            children: []
                        })

                        const nC = updateChildren(categoryBrowserInteractions.baseCategory, categoryBrowserInteractions.selectedCategoryId, pNC);

                        console.log(nC)

                        categoryBrowserInteractions.setBaseCategory(nC)
                    }

                })
            setModalIsOpen(false);
            setNewCategoryName('');
        }

    }

    const deleteSelectedCategory = () => {

        if (!categoryBrowserInteractions.selectedCategoryId || categoryBrowserInteractions.selectedCategoryId.trim() === '') {
            setSnackbarMessage('Es muss eine Kategorie ausgewählt werden');
            setSnackbarIsOpen(true);
        } else {


            getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Categories.Write"])
                .then(async (headers) => {
                    headers.append('Content-Type', 'application/json')

                    const options = {
                        method: "DELETE",
                        headers: headers,
                        body: JSON.stringify({id: categoryBrowserInteractions.selectedCategoryId})
                    };

                    const data = await fetch(myApi.url + "/categories", options)
                        .then(response => response.json())
                        .catch(error => console.log(error));

                    if (data.message === "success") {
                        let lCat = categoryBrowserInteractions.getCategoryById(categoryBrowserInteractions.selectedCategoryId);

                        if (lCat) {
                            let lChildren = lCat.children;
                            lChildren = lChildren.map((lChild) => {
                                if (!lCat)
                                    return {...lChild}

                                return {...lChild, parent_category_id: lCat.parent_category_id}
                            })

                            let lParent = categoryBrowserInteractions.getCategoryById(lCat.parent_category_id);

                            if (lParent) {
                                let lPChildren = lParent.children;

                                lPChildren = lPChildren.filter((lPChild) => lPChild.category_id !== lCat?.category_id)

                                const lNPChildren = lPChildren.concat(lChildren)

                                const nC = updateChildren(categoryBrowserInteractions.baseCategory, lCat.parent_category_id, lNPChildren)

                                categoryBrowserInteractions.setBaseCategory(nC)
                            }
                        }
                    }

                })
        }

    }

    const toggleMove = () => {
        const lMoveIsActive = !moveIsActive;

        setMoveIsActive(lMoveIsActive);

        if (lMoveIsActive && categoryBrowserInteractions.selectedCategoryId !== null)
            setMoveId(categoryBrowserInteractions.selectedCategoryId);
        else if (categoryBrowserInteractions.selectedCategoryId === null) {
            setMoveIsActive(false);
            setSnackbarMessage('Du Kannst die Basiskategorie nicht verschieben');
            setSnackbarIsOpen(true);
        }

    }

    const categoryTreeModel: ReactElement = (
        <>
            <Modal open={modelIsOpen} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                    className={'max-w-fit space-y-3'}
                >
                    <Typography variant={'h6'}>Neue Kategorie</Typography>
                    <Typography>Die Kategorie wird als Unterkategorie
                        von {categoryBrowserInteractions.getCategoryName(categoryBrowserInteractions.selectedCategoryId)} angelegt.</Typography>
                    <TextField
                        label="Neue Kategorie"
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        fullWidth
                        required
                    />
                    <Button variant="contained" color="primary" onClick={handleCategoryCreation}>
                        Erstellen
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleCloseModal} sx={{ml: 2}}>
                        Abbrechen
                    </Button>

                </Box>
            </Modal>
            <Snackbar open={snackbarIsOpen} autoHideDuration={3000} onClose={() => setSnackbarIsOpen(false)}>
                <Alert severity="error" onClose={() => setSnackbarIsOpen(false)}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Dialog open={editDialogIsOpen} onClose={handleCloseDialog}>
                <DialogTitle>Kategorie-Namen ändern</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ändere den Namen:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Category Name"
                        type="text"
                        fullWidth
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleSaveDialog} color="primary">
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

    return {
        categoryBrowser: categoryBrowser,
        categoryTreeModal: categoryTreeModel,
        interactions: {
            openCategoryCreationDialog: openCategoryCreationDialog,
            deleteSelectedCategory: deleteSelectedCategory,
            moveSelectedCategory: {
                moveIsActive: moveIsActive,
                toggleMove: toggleMove
            },
            openEditDialog: openEditDialog,
            browserInteractions: categoryBrowserInteractions
        }
    };

}