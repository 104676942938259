import React, {useState} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {Attribute} from "../../pages/Attributes";
import {getTokenResponse} from "../../utils/tokenResponse";
import {myApi} from "../../authConfig";
import {DataGrid, GridActionsCellItem, GridDeleteIcon} from "@mui/x-data-grid";
import {SelectProductCategory} from "./SelectProductCategory";
import {SelectCategory} from "./SelectCategory";

export const useAttributeEditor = ({reload}: { reload?: () => void }) => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const [attribute, setAttribute] = useState<Attribute>({
        attribute_id: "",
        isFreeText: false,
        textKi: "",
        name: "",
        isCalculated: false,
        multipleValues: false,
        autocomplete_values: [],
        isGeneralForProductGroup: false,
        isGeneralForProduct: false,
        preselectedProductCategories: [],
        preselectedCategories:[]
    });

    const [selectedAcValuesForDelete, setSelectedAcValuesForDelete] = useState<string[]>([]);

    const reset = () => {
        setAttribute({
            attribute_id: "",
            isFreeText: true,
            textKi: "",
            name: "",
            isCalculated: false,
            multipleValues: false,
            autocomplete_values: [],
            isGeneralForProductGroup: false,
            isGeneralForProduct: false,
            preselectedProductCategories: [],
            preselectedCategories:[]
        })
        setSelectedAcValuesForDelete([]);
    }

    const onCloseDialog = () => {

        setDialogIsOpen(false);
        reset();

    }

    const open = () => {
        setDialogIsOpen(true);
    }

    const load = (id: string) => {


        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Read"])
            .then(async (headers) => {


                const options = {
                    method: 'GET',
                    headers: headers,
                }

                const res = await fetch(myApi.url + "/attributes/" + id, options)
                    .then(response => response.json())
                    .catch(error => console.log(error));

                setAttribute(res)
            })

        open()
    }

    const save = () => {


        getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
            .then(async (headers) => {

                headers.append('Content-Type', 'application/json')

                const options = {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        name: attribute.name,
                        textKi: attribute.textKi,
                        isCalculated: attribute.isCalculated,
                        isFreeText: attribute.isFreeText,
                        multipleValues: attribute.multipleValues,
                        isGeneralForProductGroup: attribute.isGeneralForProductGroup,
                        isGeneralForProduct: attribute.isGeneralForProduct
                    })
                }

                if (attribute.attribute_id === "") {
                    await fetch(myApi.url + "/attributes/", options)
                        .then(response => response.json())
                        .catch(error => console.log(error));
                } else {
                    await fetch(myApi.url + "/attributes/" + attribute.attribute_id, options)
                        .then(response => response.json())
                        .catch(error => console.log(error));
                }

                onCloseDialog()
                if (reload)
                    reload()
            })


    }


    const dialog = <>
        <Dialog open={dialogIsOpen} onClose={onCloseDialog} fullWidth maxWidth={"lg"}>
            <DialogTitle>Attribut-Editor</DialogTitle>
            <form onSubmit={(e) => {
                e.preventDefault();
                save();
            }}>
                <DialogContent>
                    <div className={"w-full space-y-4"}>
                        <TextField required className={"w-full"} label={"Attributname"} value={attribute.name}
                                   onChange={(e) => setAttribute({...attribute, name: e.target.value})}></TextField>
                        <TextField multiline minRows={3} className={"w-full"} label={"Beschreibung für KI"}
                                   value={attribute.textKi}
                                   onChange={(e) => setAttribute({...attribute, textKi: e.target.value})}></TextField>
                        <Grid container>
                            <Grid item xs={6}>
                                <FormControlLabel control={<Checkbox checked={attribute.isFreeText}
                                                                     onChange={(e) => setAttribute({...attribute, isFreeText: e.target.checked})}/>}
                                                  label={"Freitexteingabe möglich"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel control={<Checkbox checked={attribute.isCalculated}
                                                                     onChange={(e) => setAttribute({...attribute, isCalculated: e.target.checked})}/>}
                                                  label={"Berechnetes Feld (soon)"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel control={<Checkbox checked={attribute.multipleValues}
                                                                     onChange={(e) => setAttribute({...attribute, multipleValues: e.target.checked})}/>}
                                                  label={"Mehrere Werte erlaubt"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel control={<Checkbox checked={attribute.isGeneralForProductGroup}
                                                                     onChange={(e) => setAttribute({...attribute, isGeneralForProductGroup: e.target.checked})}/>}
                                                  label={"Ausgewählt für alle Produktgruppen"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel control={<Checkbox checked={attribute.isGeneralForProduct}
                                                                     onChange={(e) => setAttribute({...attribute, isGeneralForProduct: e.target.checked})}/>}
                                                  label={"Ausgewählt für alle Produkt"}/>
                            </Grid>
                        </Grid>
                        <Divider>Vorschlagswerte</Divider>
                        {
                            attribute.attribute_id === "" ? (
                                <Typography>Vorschlagswerte können erst hinzugefügt werden, wenn das Attribut erstmalig
                                    gespeichert wurde.</Typography>) : (<>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink id="cat">Vorschlagswerte</InputLabel>
                                            <Select

                                                native
                                                multiple
                                                labelId="cat"
                                                label="Vorschlagswerte"
                                                value={selectedAcValuesForDelete}
                                                //@ts-ignore
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    const {options} = event.target;
                                                    const value: string[] = [];
                                                    for (let i = 0, l = options.length; i < l; i += 1) {
                                                        if (options[i].selected) {
                                                            value.push(options[i].value);
                                                        }
                                                    }
                                                    setSelectedAcValuesForDelete(value);
                                                }}

                                            >

                                                {
                                                    attribute.autocomplete_values.map((v) =>
                                                        (
                                                            <option value={v.autocomplete_id}
                                                                    key={v.autocomplete_id}>{v.name}</option>
                                                        )
                                                    )
                                                }

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className={"flex flex-col space-y-2"}>
                                            <Button color={"primary"} variant={"contained"} onClick={() => {
                                                const nN = prompt("Neuer Vorschlagswert:");

                                                if (nN) {
                                                    getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                        .then(async (headers) => {

                                                            headers.append('Content-Type', 'application/json')

                                                            const options = {
                                                                method: 'POST',
                                                                headers: headers,
                                                                body: JSON.stringify({
                                                                    name: nN,
                                                                    attribute_id: attribute.attribute_id
                                                                })
                                                            }


                                                            const lR = await fetch(myApi.url + "/attributes/" + attribute.attribute_id + "/values/", options)
                                                                .then(response => response.json())
                                                                .catch(error => console.log(error));

                                                            setAttribute({...attribute, autocomplete_values: attribute.autocomplete_values.concat(lR.data)})

                                                        })
                                                }
                                            }}>
                                                Hinzufügen
                                            </Button>
                                            <Button color={"primary"} variant={"contained"} onClick={() => {

                                                let lA = {...attribute};
                                                getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                    .then(async (headers) => {

                                                        headers.append('Content-Type', 'application/json')


                                                        for (const v1 of lA.autocomplete_values.filter((v) => selectedAcValuesForDelete.includes(v.autocomplete_id))) {


                                                            const nN = prompt("Neuer Name für " + v1.name + ":");

                                                            if (nN) {

                                                                const options = {
                                                                    method: 'POST',
                                                                    headers: headers,
                                                                    body: JSON.stringify({
                                                                        name: nN
                                                                    })
                                                                }

                                                                const lR = await fetch(myApi.url + "/attributes/" + attribute.attribute_id + "/values/" + v1.autocomplete_id, options)
                                                                    .then(response => response.json())
                                                                    .catch(error => console.log(error));


                                                                lA = {
                                                                    ...lA,
                                                                    autocomplete_values: lA.autocomplete_values.filter((val) => val.autocomplete_id !== v1.autocomplete_id).concat(lR.data)
                                                                }


                                                            }
                                                        }
                                                        setAttribute(lA)

                                                    })

                                            }}>
                                                Bearbeiten
                                            </Button>
                                            <Button color={"error"} disabled={selectedAcValuesForDelete.length === 0}
                                                    variant={"contained"} onClick={() => {
                                                let lA = {...attribute};
                                                getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                    .then(async (headers) => {

                                                        headers.append('Content-Type', 'application/json')


                                                        for (const v1 of lA.autocomplete_values.filter((v) => selectedAcValuesForDelete.includes(v.autocomplete_id))) {


                                                            const options = {
                                                                method: 'DELETE',
                                                                headers: headers
                                                            }


                                                            await fetch(myApi.url + "/attributes/" + attribute.attribute_id + "/values/" + v1.autocomplete_id, options)
                                                                .then(response => response.json())
                                                                .catch(error => console.log(error));

                                                            lA = {
                                                                ...lA,
                                                                autocomplete_values: lA.autocomplete_values.filter((val) => val.autocomplete_id !== v1.autocomplete_id)
                                                            }


                                                        }
                                                        setAttribute(lA)

                                                    })
                                            }}>
                                                Ausgewählte löschen
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>)
                        }
                        <Divider>Automatische Vorzuweisung</Divider>
                        <div className={"space-y-4"}>
                            {
                                attribute.attribute_id===""?(<Typography>Vorzuweisungn können erst gemacht werden, wenn das Attribut erstmalig gespeichert wurde, da diese direkt erzeugt werden.</Typography>):(
                                    <>
                                        <div className={"flex"}>
                                            <Typography className={"flex-grow"}>Produktkategorien</Typography>
                                            <div>
                                                <SelectProductCategory onSelection={(pC) => {
                                                    if (attribute.preselectedProductCategories.filter((p) => p.product_category.id === pC.id).length !== 0) {
                                                        alert("Diese Produktkategorie ist bereits vorhanden!")
                                                        return;
                                                    }

                                                    getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                        .then(async (headers) => {

                                                            headers.append('Content-Type', 'application/json')


                                                            const options = {
                                                                method: 'POST',
                                                                headers: headers
                                                            }


                                                            const r = await fetch(myApi.url + "/attributes/" + attribute.attribute_id + "/product_categories/" + pC.id, options)
                                                                .then(response => response.json())
                                                                .catch(error => console.log(error));

                                                            if (r.message === "success") {
                                                                setAttribute({
                                                                    ...attribute,
                                                                    preselectedProductCategories: attribute.preselectedProductCategories.concat({
                                                                        ...r.data,
                                                                        product_category: pC
                                                                    })
                                                                })
                                                            }

                                                        })
                                                }}>
                                                    <Button variant={"contained"} color={"primary"}>Hinzufügen</Button>
                                                </SelectProductCategory>
                                            </div>
                                        </div>
                                        <DataGrid columns={
                                            [
                                                {
                                                    field: 'id',
                                                    headerName: "ID-Nummer"
                                                },
                                                {
                                                    field: 'name',
                                                    headerName: "Name"
                                                },
                                                {
                                                    field: 'activeForProductGroups',
                                                    headerName: 'Produktgruppen',
                                                    editable: true,
                                                    type: 'boolean',
                                                    minWidth: 150
                                                },
                                                {
                                                    field: 'activeForProducts',
                                                    headerName: 'Produkte',
                                                    editable: true,
                                                    type: 'boolean'
                                                },
                                                {
                                                    field: 'actions',
                                                    headerName: '',
                                                    type: 'actions',
                                                    getActions: (({id}) => {
                                                        return [<GridActionsCellItem label={"Löschen"} icon={<GridDeleteIcon/>}
                                                                                     onClick={() => {
                                                                                         getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                                                             .then(async (headers) => {

                                                                                                 headers.append('Content-Type', 'application/json')


                                                                                                 const options = {
                                                                                                     method: 'DELETE',
                                                                                                     headers: headers
                                                                                                 }


                                                                                                 const res = await fetch(myApi.url + "/attributes/" + attribute.attribute_id + "/product_categories/" + id, options)
                                                                                                     .then(response => response.json())
                                                                                                     .catch(error => console.log(error));

                                                                                                 if (res.message === "success") {
                                                                                                     setAttribute({
                                                                                                         ...attribute,
                                                                                                         preselectedProductCategories: attribute.preselectedProductCategories.filter((p) => p.product_category.id !== id)
                                                                                                     })
                                                                                                 }

                                                                                             })
                                                                                     }}
                                                        />]
                                                    })
                                                }
                                            ]
                                        } rows={attribute.preselectedProductCategories.map((p) => {
                                            return {
                                                id: p.product_category.id,
                                                name: p.product_category.name,
                                                activeForProductGroups: p.activeForProductGroups,
                                                activeForProducts: p.activeForProducts
                                            }
                                        })}

                                                  onCellEditStop={(params) => {
                                                      getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                          .then(async (headers) => {

                                                              headers.append('Content-Type', 'application/json')

                                                              let body: { activeForProductGroups?: boolean, activeForProducts?: boolean } = {};

                                                              if (params.field === "activeForProductGroups") {
                                                                  body = {
                                                                      activeForProductGroups: !params.value
                                                                  }
                                                              }

                                                              if (params.field === "activeForProducts") {
                                                                  body = {
                                                                      activeForProducts: !params.value
                                                                  }


                                                              }

                                                              setAttribute({
                                                                  ...attribute,
                                                                  preselectedProductCategories: attribute.preselectedProductCategories.filter((p) => p.product_category.id !== params.id)
                                                                      .concat(
                                                                          attribute.preselectedProductCategories.filter((p) => p.product_category.id === params.id).map((p) => {
                                                                              return {
                                                                                  ...p,
                                                                                  ...body
                                                                              }
                                                                          })
                                                                      )
                                                              })

                                                              if (params.field === "activeForProductGroups" || params.field === "activeForProducts") {
                                                                  const options = {
                                                                      method: 'POST',
                                                                      headers: headers,
                                                                      body: JSON.stringify(body)
                                                                  }


                                                                  await fetch(myApi.url + "/attributes/" + attribute.attribute_id + "/product_categories/" + params.id, options)
                                                                      .then(response => response.json())
                                                                      .catch(error => console.log(error));
                                                              }


                                                          })
                                                  }}

                                        />
                                        <div className={"flex"}>
                                            <Typography className={"flex-grow"}>Kategorien</Typography>
                                            <div>
                                                <SelectCategory title={"Kategorie hinzufügen"} onSelection={(catId,cat)=>{
                                                    if(!catId) {
                                                        alert("Es kann nicht die Basiskategorie ausgewählt werden.")
                                                        return;
                                                    }
                                                    if(attribute.preselectedCategories.filter((p)=>p.category.category_id===catId).length!==0) {
                                                        alert("Diese Kategorie ist bereits vorhanden!")
                                                        return;
                                                    }
                                                    getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                        .then(async (headers) => {

                                                            headers.append('Content-Type', 'application/json')


                                                            const options = {
                                                                method: 'POST',
                                                                headers: headers
                                                            }


                                                            const r = await fetch(myApi.url + "/attributes/" + attribute.attribute_id + "/categories/" +catId, options)
                                                                .then(response => response.json())
                                                                .catch(error => console.log(error));

                                                            if (r.message === "success") {
                                                                setAttribute({
                                                                    ...attribute,
                                                                    preselectedCategories:attribute.preselectedCategories.concat({
                                                                        ...r.data,
                                                                        category:cat
                                                                    })
                                                                })
                                                            }

                                                        })
                                                }}>
                                                    Hinzufügen
                                                </SelectCategory>
                                            </div>
                                        </div>
                                        <DataGrid columns={
                                            [
                                                {
                                                    field: 'id',
                                                    headerName: "ID-Nummer"
                                                },
                                                {
                                                    field: 'name',
                                                    headerName: "Name"
                                                },
                                                {
                                                    field: 'activeForProductGroups',
                                                    headerName: 'Produktgruppen',
                                                    editable: true,
                                                    type: 'boolean',
                                                    minWidth: 150
                                                },
                                                {
                                                    field: 'activeForProducts',
                                                    headerName: 'Produkte',
                                                    editable: true,
                                                    type: 'boolean'
                                                },
                                                {
                                                    field: 'actions',
                                                    headerName: '',
                                                    type: 'actions',
                                                    getActions: (({id}) => {
                                                        return [<GridActionsCellItem label={"Löschen"} icon={<GridDeleteIcon/>}
                                                                                     onClick={() => {
                                                                                         getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                                                             .then(async (headers) => {

                                                                                                 headers.append('Content-Type', 'application/json')


                                                                                                 const options = {
                                                                                                     method: 'DELETE',
                                                                                                     headers: headers
                                                                                                 }


                                                                                                 const res = await fetch(myApi.url + "/attributes/" + attribute.attribute_id + "/categories/" + id, options)
                                                                                                     .then(response => response.json())
                                                                                                     .catch(error => console.log(error));

                                                                                                 if (res.message === "success") {
                                                                                                     setAttribute({
                                                                                                         ...attribute,
                                                                                                         preselectedCategories:attribute.preselectedCategories.filter((p)=>p.category.category_id!==id)
                                                                                                     })
                                                                                                 }

                                                                                             })
                                                                                     }}
                                                        />]
                                                    })
                                                }
                                            ]
                                        } rows={attribute.preselectedCategories.map((p) => {
                                            return {
                                                id: p.category.category_id,
                                                name: p.category.name,
                                                activeForProductGroups: p.activeForProductGroups,
                                                activeForProducts: p.activeForProducts
                                            }
                                        })}

                                                  onCellEditStop={(params) => {
                                                      getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                                          .then(async (headers) => {

                                                              headers.append('Content-Type', 'application/json')

                                                              let body: { activeForProductGroups?: boolean, activeForProducts?: boolean } = {};

                                                              if (params.field === "activeForProductGroups") {
                                                                  body = {
                                                                      activeForProductGroups: !params.value
                                                                  }
                                                              }

                                                              if (params.field === "activeForProducts") {
                                                                  body = {
                                                                      activeForProducts: !params.value
                                                                  }


                                                              }

                                                              setAttribute({
                                                                  ...attribute,
                                                                  preselectedCategories: attribute.preselectedCategories.filter((p) => p.category.category_id !== params.id)
                                                                      .concat(
                                                                          attribute.preselectedCategories.filter((p) => p.category.category_id === params.id).map((p) => {
                                                                              return {
                                                                                  ...p,
                                                                                  ...body
                                                                              }
                                                                          })
                                                                      )
                                                              })

                                                              if (params.field === "activeForProductGroups" || params.field === "activeForProducts") {
                                                                  const options = {
                                                                      method: 'POST',
                                                                      headers: headers,
                                                                      body: JSON.stringify(body)
                                                                  }


                                                                  await fetch(myApi.url + "/attributes/" + attribute.attribute_id + "/categories/" + params.id, options)
                                                                      .then(response => response.json())
                                                                      .catch(error => console.log(error));
                                                              }


                                                          })
                                                  }}

                                        />
                                    </>
                                )
                            }
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    {
                        attribute.attribute_id !== "" && (<Button onClick={() => {

                            if(attribute.preselectedProductCategories.length===0&&attribute.preselectedCategories.length===0)
                            {
                                getTokenResponse(["api://58eba143-b1bd-474a-86dc-966e7236cf7d/Attributes.Edit"])
                                    .then(async (headers) => {

                                        headers.append('Content-Type', 'application/json')

                                        const options = {
                                            method: 'DELETE',
                                            headers: headers
                                        }


                                        await fetch(myApi.url + "/attributes/" + attribute.attribute_id, options)
                                            .then(response => response.json())
                                            .catch(error => console.log(error));


                                        onCloseDialog()
                                        if (reload)
                                            reload()
                                    })
                            }
                            else
                                alert("Es müssen erst alle Zuweisungen gelöscht werden.")
                        }} color={"error"}>
                            Löschen
                        </Button>)
                    }
                    <Button onClick={() => onCloseDialog()} color={"error"}>
                        Abbrechen
                    </Button>
                    <Button type={"submit"} color={"primary"}>
                        Speichern
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    </>;

    return {
        dialog: dialog,
        interactions: {
            open: open,
            load: load
        }
    }

}