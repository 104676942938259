import {Button, Paper, Typography} from "@mui/material";
import {SimpleAttribute, useAttributeGrid} from "../components/data_grids/AttributeGrid";
import {useState} from "react";
import {useAttributeEditor} from "../components/dialogs/AttributeEditor";
import {ProductCategory} from "../components/product_categories";
import {CategoryPrisma} from "../types/Category";
import {ACValue} from "../components/dialogs/AttributeValuesEditor";

export interface Attribute extends SimpleAttribute {
    textKi:string|null,
    isFreeText:boolean,
    isCalculated:boolean,
    multipleValues:boolean,
    autocomplete_values: ACValue[],
    isGeneralForProductGroup:boolean,
    isGeneralForProduct:boolean,
    preselectedProductCategories: {
        product_category: ProductCategory,
        activeForProductGroups:boolean,
        activeForProducts:boolean
    }[],
    preselectedCategories: {
        category: CategoryPrisma,
        activeForProductGroups:boolean,
        activeForProducts:boolean
    }[]
}

export const Attributes = () => {

    const [selectedId,setSelectedId] = useState("");


    const {grid,renewData } = useAttributeGrid({
        onSelection: (type,id)=>{
            if(type==="NONE")
                setSelectedId("");
            else if(id)
                setSelectedId(id);
        }
    });

    const {dialog,interactions} = useAttributeEditor({
        reload: renewData
    });

    return (<>
        <div className={"m-1"}>
            <div className={"p-4"}>
                <Paper elevation={3} className={"p-2 space-y-2"}>
                    <div className={"flex"}>
                        <Typography className={"flex-grow"} variant={"h5"}>Attribute</Typography>
                        <Button color={"primary"} onClick={()=>{
                            if(selectedId==="")
                            {
                                interactions.open()
                            }
                            else
                            {
                                interactions.load(selectedId)
                            }
                        }}>{selectedId===""?"Erstellen":"Bearbeiten"}</Button>
                    </div>
                    {grid}
                </Paper>
            </div>
        </div>
        {dialog}
    </>);
}