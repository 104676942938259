import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React, {ReactElement, useEffect, useState} from "react";
import {useCategoryTree} from "../../hooks/useCategoryTree";
import {Category} from "../../types/Category";

export const SelectCategory = ({children,title,onSelection}:{children?:ReactElement|string,title:string,onSelection?:(category_id:string|null,category_element?:Category|null)=>void}) : ReactElement => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const {categoryBrowser:browser, categoryBrowserInteractions:interactions} = useCategoryTree({},false);


    const openDialog = () => {
        setDialogIsOpen(true);
    }

    const onCloseDialog = () => {
        setDialogIsOpen(false)
    }

    const onCommit = () => {
        onCloseDialog()

        if(onSelection)
            onSelection(interactions.selectedCategoryId,interactions.getCategoryById(interactions.selectedCategoryId))
    }

    useEffect(() => {
        if(dialogIsOpen) {
            interactions.initialize()
        }

    }, [dialogIsOpen,interactions]);


    return(
        <>
            <Button variant={"contained"} color={"primary"} onClick={openDialog}>
                {children}
            </Button>
            <Dialog open={dialogIsOpen} onClose={onCloseDialog}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte wähle eine Kategorie aus und bestätige.
                    </DialogContentText>
                    {browser}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseDialog} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={onCommit} color="primary">
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}