import React, {ReactElement, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Editor} from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

export const TypeTextDialog = ({children,text,save}:{children?:ReactElement|string,text:string,save?:(html:string)=>void}) => {

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const [editor,setEditor] = useState<EditorState>(EditorState.createEmpty())

    const openDialog = () => {
        setDialogIsOpen(true);
        setEditor(EditorState.createWithContent(stateFromHTML(text)))
    }

    const onCloseDialog = () => {
        setDialogIsOpen(false)
    }

    const onCommit = () => {
        onCloseDialog()
        if(save)
            save(stateToHTML(editor.getCurrentContent()))
    }

    return <>
        <div onClick={openDialog}>
            {children}
        </div>
        <Dialog open={dialogIsOpen} onClose={onCloseDialog} fullWidth maxWidth={"lg"}>
            <DialogTitle>Text-Editor</DialogTitle>
            <DialogContent>
                <article className={"prose-xl w-full"}>
                    <Editor
                        editorState={editor}
                        onEditorStateChange={(e)=>setEditor(e)}

                    />
                </article>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialog} color="primary">
                    Abbrechen
                </Button>
                <Button onClick={onCommit} color="primary">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    </>
}